import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { userInitial } from '@store/initials/userInitial'
import { Type, UserTypes } from '@store/types/userTypes'

const userSlice = createSlice({
  name: 'user',
  initialState: userInitial,
  reducers: {
    setFirstLogin: (state: UserTypes, { payload }: PayloadAction<boolean>) => {
      if (state.isFirstLogin === payload) return
      state.isFirstLogin = payload
    },
    setLeapUserId: (state: UserTypes, { payload }: PayloadAction<string>) => {
      if (state.leapUserId === payload) return
      state.leapUserId = payload
    },
    setUserType: (state: UserTypes, { payload }: PayloadAction<Type>) =>
      void Object.assign(state.typeUser, payload),
    setUserInfo: (state: UserTypes, { payload }: PayloadAction<string>) => {
      state.userName = payload
    },
    resetUserInfo: () => userInitial,
    setIsAcceptedTermAndCondition: (state: UserTypes, { payload }: PayloadAction<boolean>) => {
      if (state.isAcceptedTermAndCondition === payload) return
      state.isAcceptedTermAndCondition = payload
    },
    acceptTermAndCondition: (state: UserTypes) => {
      state.isAcceptedTermAndCondition = true
    }
  }
})

export const {
  setFirstLogin,
  setLeapUserId,
  setUserType,
  setUserInfo,
  resetUserInfo,
  setIsAcceptedTermAndCondition,
  acceptTermAndCondition
} = userSlice.actions

export default userSlice.reducer
