import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ICommonSearch } from '@models/common'
import { INoticeMatches, IQueryNoticeMatches } from '@models/matches'
import { matchInitial } from '@store/initials/matchInitial'

const matchSlice = createSlice({
  name: 'match',
  initialState: matchInitial,
  reducers: {
    savingSearchNoticesMatch: (
      state: ICommonSearch<IQueryNoticeMatches, INoticeMatches[]>,
      action: PayloadAction<ICommonSearch<IQueryNoticeMatches, INoticeMatches[]>>
    ) => {
      Object.assign(state, action.payload)
    },
    resetSearchNoticesMatch: () => matchInitial
  }
})

export const { savingSearchNoticesMatch, resetSearchNoticesMatch } = matchSlice.actions

export default matchSlice.reducer
