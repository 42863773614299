import axios, { AxiosRequestHeaders } from 'axios'
import { apiErrorLogger } from '@utils/datadog'
import { validateDomain } from '@utils/apis'
import { authHeader } from '@utils/authenticate'

const get = async (path: string, options?: any) => {
  options?.willCallAPI?.()
  validateDomain(path)
  return axios
    .get(path, {
      headers: authHeader(),
      responseType: options?.type ?? 'json'
    })
    .then(value => {
      options?.onSuccess?.(value.data)
      return value.data
    })
    .catch(err => {
      apiErrorLogger(`path: ${path}`, { path, options, err })
      options?.onError?.(err)
      return err
    })
    .finally(() => options?.didCallAPI?.())
}

const post = (path: string, params: any, options?: any) => {
  options?.willCallAPI?.()
  validateDomain(path)
  return axios
    .post(path, params, {
      headers: authHeader(),
      responseType: options?.type ?? 'json'
    })
    .then(value => {
      options?.onSuccess?.(value.data, value.status)
      return {
        data: value.data,
        status: value.status
      }
    })
    .catch(err => {
      apiErrorLogger(`path: ${path}`, { path, options, err, params })
      options?.onError?.(err)
      return err
    })
    .finally(() => options?.didCallAPI?.())
}

const patch = (path: string, params: any, options?: any) => {
  options?.willCallAPI?.()
  validateDomain(path)
  let headers: AxiosRequestHeaders = authHeader()
  if (options?.ifMatch) {
    headers = { ...headers, 'if-match': options.ifMatch }
  }
  return axios
    .patch(path, params, {
      headers: { ...headers },
      responseType: options?.type ?? 'json'
    })
    .then(value => {
      options?.onSuccess?.(value.data, value.status)
      return value.data
    })
    .catch(err => {
      apiErrorLogger(`path: ${path}`, { path, options, err, params })
      options?.onError?.(err)
      return err
    })
    .finally(() => options?.didCallAPI?.())
}

const put = (path: string, params: any, options?: any) => {
  options?.willCallAPI?.()
  validateDomain(path)
  let headers: AxiosRequestHeaders = authHeader()
  if (options?.ifMatch) {
    headers = { ...headers, 'if-match': options.ifMatch }
  }
  return axios
    .put(path, params, {
      headers: { ...headers },
      responseType: options?.type ?? 'json'
    })
    .then(value => {
      options?.onSuccess?.(value.data, value.status)
      return value.data
    })
    .catch(err => {
      apiErrorLogger(`path: ${path}`, { path, options, err, params })
      options?.onError?.(err)
      return err
    })
    .finally(() => options?.didCallAPI?.())
}

const del = (path: string, options?: any) => {
  options?.willCallAPI?.()
  validateDomain(path)
  let headers: AxiosRequestHeaders = authHeader()
  if (options?.ifMatch) {
    headers = { ...headers, 'if-match': options.ifMatch }
  }
  return axios
    .delete(path, {
      headers: { ...headers },
      responseType: options?.type ?? 'json'
    })
    .then(value => {
      options?.onSuccess?.(value.data, value.status)
      return value.data
    })
    .catch(err => {
      apiErrorLogger(`path: ${path}`, { path, options, err })
      options?.onError?.(err)
      return err
    })
    .finally(() => options?.didCallAPI?.())
}

const getResponseTypeBlob = async (path: string, options?: any) => {
  options?.willCallAPI?.()
  validateDomain(path)
  return axios
    .get(path, {
      headers: {
        Accept: 'application/pdf',
        'Content-Type': 'application/pdf',
        Authorization: `Bearer ${localStorage.getItem('TOKEN') ?? ''}`
      },
      responseType: 'blob'
    })
    .then(value => {
      options?.onSuccess?.(value.data)
      return value.data
    })
    .catch(err => {
      apiErrorLogger(`path: ${path}`, { path, options, err })
      options?.onError?.(err)
      return err
    })
    .finally(() => options?.didCallAPI?.())
}

export default {
  get,
  post,
  put,
  del,
  patch,
  getResponseTypeBlob
}
