import { createBrowserRouter } from 'react-router-dom'
import { lazy as ReactLazy } from 'react'

import { APP_URL } from '@constants/index'
import publicRoutes from '@routes/publicRoutes'

const lazy = (promise: () => any) =>
  ReactLazy(() => {
    return Promise.all([promise(), new Promise(res => setTimeout(res, 500))]).then(
      ([moduleExports]) => moduleExports
    )
  })

const RootBoundary = lazy(() => import('@components/RootBoundary'))
const MainLayout = lazy(() => import('@components/MainLayout'))
const WrapperSimpleLayout = lazy(() => import('@components/WrapperSimpleLayout'))
const Onboarding = lazy(() => import('@containers/Onboarding'))
const WillList = lazy(() => import('@containers/Will/WillList'))
const Login = lazy(() => import('@containers/Authenticate/Login'))
const SetupSetting = lazy(() => import('@containers/SetupSetting'))
const NoticeList = lazy(() => import('@containers/Notice/NoticeList'))
const OrderDisplay = lazy(() => import('@containers/OrderDisplay'))
const Settings = lazy(() => import('@containers/Settings'))
const ProbateNoticeList = lazy(() => import('@containers/ProbateNotice/ProbateNoticeList'))

const router = createBrowserRouter([
  {
    path: APP_URL.BASE,
    Component: MainLayout,
    errorElement: <RootBoundary />,
    children: [
      {
        index: true,
        Component: WillList
      },
      {
        path: APP_URL.FIND_WILLS,
        Component: WillList
      },
      {
        path: APP_URL.NOTICES,
        Component: NoticeList
      },
      {
        path: APP_URL.PROBATE_NOTICES,
        Component: ProbateNoticeList
      },
      {
        path: APP_URL.SETTINGS,
        Component: Settings
      },
      {
        path: `${APP_URL.ORDER_DISPLAY}/:ldmCoreOrderId`,
        Component: OrderDisplay
      }
    ]
  },
  {
    path: APP_URL.ON_BOARDING,
    Component: WrapperSimpleLayout,
    children: [
      {
        index: true,
        Component: Onboarding
      }
    ]
  },
  {
    path: APP_URL.SETUP_SETTING,
    Component: WrapperSimpleLayout,
    children: [
      {
        index: true,
        Component: SetupSetting
      }
    ]
  },
  {
    path: APP_URL.LOGIN,
    Component: WrapperSimpleLayout,
    children: [
      {
        index: true,
        Component: Login
      }
    ]
  },
  ...publicRoutes
])

export default router
