export const getLinkKey = (index: number) => `link[${index}]`
export const getBoldKey = (index: number) => `bold[${index}]`
export const getItalicKey = (index: number) => `italic[${index}]`

// [abc](abc) -> abc
export const linkRegex = /\[(.*?)\]\((.*?)\)/g
// **abc** -> abc
export const boldRegex = /\*\*(.*?)\*\*/g
// _abc_ -> abc
export const italicRegex = /_([^_]+)_/g
// ${abc} -> abc
export const dynamicVariableRegex = /\${(.*?)}/g
// https regex
export const httpsRegex = /^https:\/\//

export const findLinks = (text: string) => {
  let newText = text
  const links: any[] = []
  const regex = /\[(.*?)\]\((.*?)\)/g
  let match: RegExpExecArray | null
  let index = 0
  while ((match = regex.exec(text)) !== null) {
    const link = match[0]
    links.push({ text: match[1], link: match[2] })
    newText = newText.replace(link, getLinkKey(index))
    index++
  }
  return { text: newText, links }
}

export const findMarkdownKey = (text: string, regex: RegExp, getKey: (index: number) => string) => {
  let newText = text

  const values: any[] = []
  let match: RegExpExecArray | null
  let index = 0
  while ((match = regex.exec(text)) !== null) {
    const bold = match[0]
    values.push(match[1])
    newText = newText.replace(bold, getKey(index))
    index++
  }
  return { text: newText, values }
}

export const Link = ({
  text,
  link,
  action
}: {
  text: string
  link: string
  action?: (link: string) => void
}) => {
  return (
    <span
      style={{ color: '#0C7E96', textDecoration: 'underline' }}
      onClick={() => {
        action?.(link)
      }}
    >
      {text}
    </span>
  )
}

export const Bold = ({ text }: { text: string }) => {
  return <b>{text}</b>
}

export const Italic = ({ text }: { text: string }) => {
  return <i>{text}</i>
}
