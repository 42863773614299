import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ICommonSearch } from '@models/common'
import { ISearchProbateNotice, IProbateNotices } from '@models/probateNotices'
import { probateNoticeInitial } from '../initials/probateNoticeInitial'

const probateNoticeSlice = createSlice({
  name: 'probateNotice',
  initialState: probateNoticeInitial,
  reducers: {
    savingSearchProbateNotices: (
      state: ICommonSearch<ISearchProbateNotice, IProbateNotices[]>,
      action: PayloadAction<ICommonSearch<ISearchProbateNotice, IProbateNotices[]>>
    ) => {
      Object.assign(state, action.payload)
    },
    resetSearchProbateNotices: () => probateNoticeInitial
  }
})

export const { savingSearchProbateNotices, resetSearchProbateNotices } = probateNoticeSlice.actions

export default probateNoticeSlice.reducer
