import { SORTING_VALUE } from '@containers/Notice/constant'
import { NoticesType } from '@store/types/noticeTypes'

export const noticeInitial: NoticesType = {
  searchData: {
    search: {
      name: '',
      newspaperIds: [],
      isAdvanceSearch: true,
      orderByDateOfDeath: SORTING_VALUE.DEFAULT,
      orderByPublishedDate: SORTING_VALUE.DESCENDING,
      postCode: '',
      dateRange: 0,
      searchRadius: 0
    },
    data: [],
    currentPage: 0,
    totalPage: 0
  },
  listSource: [],
  listPostCode: []
}
