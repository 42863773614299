import { LogsEvent, datadogLogs } from '@datadog/browser-logs'
import { LOG_ERROR_CONVENTION } from '@containers/Settings/constant'

type ILogger = {
  error: (message: string, messageContext?: object | undefined) => void
  warn: (message: string, messageContext?: object | undefined) => void
  info: (message: string, messageContext?: object | undefined) => void
  debug: (message: string, messageContext?: object | undefined) => void
  critical: (message: string, messageContext?: object | undefined) => void
  alert: (message: string, messageContext?: object | undefined) => void
  emerg: (message: string, messageContext?: object | undefined) => void
}

type typesDDConfig = {
  clientToken: string
  env: string
  service: string
  site: string
}

export const logger: ILogger = (() => {
  const env = process.env.BUILD_ENVIRONMENT || 'development'
  const localConsole = {
    ...console,
    critical: console.error,
    alert: console.error,
    emerg: console.error
  }
  let currentLogger: ILogger = env === 'development' ? localConsole : datadogLogs.logger

  return {
    error: (message: string, messageContext?: object | undefined) => {
      currentLogger.error(`${env} - ${message}`, cleanseLoggingContext(messageContext))
    },
    warn: (message: string, messageContext?: object | undefined) => {
      currentLogger.warn(`${env} - ${message}`, cleanseLoggingContext(messageContext))
    },
    info: (message: string, messageContext?: object | undefined) => {
      currentLogger.info(`${env} - ${message}`, cleanseLoggingContext(messageContext))
    },
    debug: (message: string, messageContext?: object | undefined) => {
      currentLogger.debug(`${env} - ${message}`, cleanseLoggingContext(messageContext))
    },
    critical: (message: string, messageContext?: object | undefined) => {
      currentLogger.critical(`${env} - ${message}`, cleanseLoggingContext(messageContext))
    },
    alert: (message: string, messageContext?: object | undefined) => {
      currentLogger.alert(`${env} - ${message}`, cleanseLoggingContext(messageContext))
    },
    emerg: (message: string, messageContext?: object | undefined) => {
      currentLogger.emerg(`${env} - ${message}`, cleanseLoggingContext(messageContext))
    },
    setContext: (context: object) => {
      datadogLogs.setGlobalContext({
        ...datadogLogs.getGlobalContext(),
        ...cleanseLoggingContext(context)
      })
    }
  }
})()

const keywordIsSensitive = (keyword: string) => {
  const env = process.env.NODE_ENV || ''
  if (typeof keyword !== 'string' || !keyword?.length || env === 'development') return false
  const regex = /.*(phone|token|email|password|authorisation|authorization|name).*/gi
  return regex.test(keyword)
}

const cleanseLoggingContext = (context: any) => {
  if (!context || typeof context !== 'object') return context

  return Object.keys(context).reduce((acc, currentKey) => {
    // If a property is sensitive, don't log it!
    return {
      ...acc,
      [currentKey]: keywordIsSensitive(currentKey) ? '***' : context[currentKey]
    }
  }, {})
}

const redactSensitiveLogData = (log: LogsEvent): boolean => {
  // remove email from view url
  log.message = log.message.replace(/email=[^&]*/, 'email=REDACTED')
  log.view.url = log.view.url.replace(/email=[^&]*/, 'email=REDACTED')
  return true
}

export const initialiseLogger = (ddConfig: typesDDConfig) => {
  const { clientToken, env, service, site } = ddConfig

  const isDev = env === 'development'
  try {
    datadogLogs.init({
      clientToken: clientToken,
      site: site,
      service: service,
      env: env,
      sessionSampleRate: 100,
      forwardErrorsToLogs: !isDev,
      trackSessionAcrossSubdomains: !isDev,
      forwardConsoleLogs: !isDev ? ['error'] : undefined,
      // Scrub sensitive data from our Browser logs
      beforeSend: redactSensitiveLogData
    })
    // Enforce all logs to go to datadog via http
    datadogLogs.logger.setHandler('http')
    logger.info('Datadog Logs initialised')

    return datadogLogs
  } catch (error: any) {
    console.error(JSON.stringify(error))
  }
}

export const apiErrorLogger = (message: string, error: any) => {
  logger.error(`${LOG_ERROR_CONVENTION.API_ERROR} - ${message}`, { error })
}

export const pageErrorLogger = (message: string, error: any) => {
  logger.error(`${LOG_ERROR_CONVENTION.PAGE_ERROR} - ${message}`, { error })
}

export const signalRErrorLogger = (message: string, error?: any) => {
  logger.error(`${LOG_ERROR_CONVENTION.SIGNAL_R_ERROR} - ${message}`, { error })
}

export const unknownErrorLogger = (message: string, error: any) => {
  logger.alert(`${LOG_ERROR_CONVENTION.UNKNOWN_ERROR} - ${message}`, { error })
}

export const permissionErrorLogger = (message: string, error: any) => {
  logger.error(`${LOG_ERROR_CONVENTION.PERMISSION_ERROR} - ${message}`, { error })
}

export const hookErrorLogger = (message: string, error: any) => {
  logger.error(`${LOG_ERROR_CONVENTION.HOOK_ERROR} - ${message}`, { error })
}
