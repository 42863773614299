import { AxiosError } from 'axios'
import { ToastContextProps } from '@infotrack/zenith-ui'

import { logout } from './authenticate'
import { PERMISSION_DENIED_MESSAGE } from '@constants/index'
import { permissionErrorLogger, unknownErrorLogger } from '@utils/datadog'

export const getDomain = (): string | undefined => process.env.REACT_APP_ENDPOINT || ''
export const getSignalREndpoint = (): string | undefined =>
  process.env.REACT_APP_SIGNALR_ENDPOINT || ''

export const validateDomain = (path: string): void => {
  const isValidDomain = path.split('/').every(item => item !== 'undefined')
  if (!isValidDomain) {
    unknownErrorLogger('Invalid domain', new Error('Invalid domain'))
    throw new Error('Can not detect domain in process.env.REACT_APP_ENDPOINT')
  }
}

interface ErrorHandlerParams {
  error: AxiosError
  dispatch: any
  customErrorCallback?: Record<string, () => void>
  toastContext: ToastContextProps
  navigate: any
}

export const apiResponseErrorHandler = ({
  error,
  dispatch,
  toastContext,
  navigate,
  customErrorCallback
}: ErrorHandlerParams) => {
  switch (error.response?.status) {
    case 400:
      if (customErrorCallback && customErrorCallback['400']) {
        customErrorCallback['400']()
      }
      return
    case 401:
      logout(dispatch, navigate)
      return
    case 403:
      const responseData = error.response?.data.message
      const message = typeof responseData === 'string' ? responseData : PERMISSION_DENIED_MESSAGE
      permissionErrorLogger(message, error)
      toastContext.error({
        title: 'Error',
        message: message,
        duration: 3
      })
      if (customErrorCallback && customErrorCallback['403']) {
        customErrorCallback['403']()
      }
      return
    default:
      unknownErrorLogger('', error)
      toastContext.error({
        title: 'Error',
        message: 'Please try again in a few moments. if the problem persists, contact help desk.',
        duration: 3
      })
      return
  }
}
