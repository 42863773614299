import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ICommonSearch } from '@models/common'
import { IQueryReceivedWillRequestsAsync, IReceivedWillRequests } from '@models/silentWills'
import { receivedWillIntial } from '@store/initials/silentWillIntial'

const receivedWillSlice = createSlice({
  name: 'receivedWillSlice',
  initialState: receivedWillIntial,
  reducers: {
    savingReceivedWillRequest: (
      state: ICommonSearch<IQueryReceivedWillRequestsAsync, IReceivedWillRequests[]>,
      action: PayloadAction<ICommonSearch<IQueryReceivedWillRequestsAsync, IReceivedWillRequests[]>>
    ) => {
      Object.assign(state, action.payload)
    },
    resetReceivedWillRequest: () => receivedWillIntial
  }
})

export const { savingReceivedWillRequest, resetReceivedWillRequest } = receivedWillSlice.actions

export default receivedWillSlice.reducer
