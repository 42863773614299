import { MyWillTypes } from '@store/types/myWillTypes'

export const myWillInitial: MyWillTypes = {
  myWill: {
    listDisable: [],
    listWill: {
      currentPage: 0,
      data: [],
      search: {
        selectedDate: '',
        hiddenOnly: undefined,
        newPage: undefined,
        searchKeyword: undefined,
        visibleOnly: undefined
      },
      totalPage: 0
    }
  },
  invalidWill: {
    currentPage: 0,
    data: [],
    totalPage: 0
  },
  totalNotification: {},
}
