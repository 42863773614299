import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { myWillInitial } from '@store/initials/myWillInitial'
import {
  InValidWillType,
  MyWillType,
  MyWillTypes,
  PrivacyWillTypes
} from '@store/types/myWillTypes'
import { IQueryGetListWills, ITotalNotification } from '@models/myWill'

const myWillSlice = createSlice({
  name: 'myWill',
  initialState: myWillInitial,
  reducers: {
    setMyWill: (state: MyWillTypes, { payload }: PayloadAction<MyWillType>) => {
      Object.assign(state.myWill, payload)
    },
    setInvalidWill: (state: MyWillTypes, { payload }: PayloadAction<InValidWillType>) => {
      Object.assign(state.invalidWill, payload)
    },
    setCurrentTotalNotification: (
      state: MyWillTypes,
      { payload }: PayloadAction<ITotalNotification>
    ) => {
      Object.assign(state.totalNotification, payload)
    },
    updatePrivacyWill: (
      state: MyWillTypes,
      { payload: { indexWill, isHidden, willId } }: PayloadAction<PrivacyWillTypes>
    ) => {
      const willSelected = state.myWill.listWill.data[indexWill]
      willSelected.isHidden = isHidden

      const disableArr = state.myWill.listDisable
      const indexItem = disableArr.indexOf(willId)
      if (indexItem > -1) {
        disableArr.splice(indexItem, 1)
      } else {
        disableArr.push(willId)
      }
    },
    updateQuerySearch: (state: MyWillTypes, { payload }: PayloadAction<IQueryGetListWills>) => {
      Object.assign(state.myWill.listWill.search, payload)
    },
    resetMyWills: () => myWillInitial
  }
})

export const {
  setInvalidWill,
  setMyWill,
  setCurrentTotalNotification,
  updatePrivacyWill,
  updateQuerySearch,
  resetMyWills
} = myWillSlice.actions

export default myWillSlice.reducer
