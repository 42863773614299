import { MarkdownKey } from '@constants/markdown'

export enum GROUP_SETTINGS {
  Notifications,
  Register_Will,
  Privacy,
  Your_Details,
  Manage_Users,
  Subcriptions
}

export const listGroup = [
  {
    key: 'notifications',
    markdownKey: MarkdownKey.SETTING_MENU_NOTIFICATION_LABEL,
    icon: 'notifications',
    value: `${GROUP_SETTINGS.Notifications}`
  },
  {
    key: 'registerYourWillsMatchData',
    markdownKey: MarkdownKey.SETTING_MENU_REGISTER_WILL_LABEL,
    icon: 'file_copy',
    value: `${GROUP_SETTINGS.Register_Will}`
  },
  {
    key: 'privacy',
    markdownKey: MarkdownKey.SETTING_MENU_PRIVACY_LABEL,
    icon: 'lock',
    value: `${GROUP_SETTINGS.Privacy}`
  },
  {
    key: 'yourDetails',
    markdownKey: MarkdownKey.SETTING_MENU_YOUR_DETAILS_LABEL,
    icon: 'person',
    value: `${GROUP_SETTINGS.Your_Details}`
  },
  {
    key: 'manageUsers',
    markdownKey: MarkdownKey.SETTING_MENU_MANAGE_USER_LABEL,
    icon: 'groups',
    value: `${GROUP_SETTINGS.Manage_Users}`
  }
]

export const initialModeMangeUser = {
  modeView: true,
  modeInviteUser: false,
  modeEditUser: false
}

export enum PrivacySettingEnum {
  UNLOCKED = 'UNLOCKED',
  LOCKED = 'LOCKED'
}

export const LOG_ERROR_CONVENTION = {
  API_ERROR: 'API_ERROR',
  PAGE_ERROR: 'PAGE_ERROR',
  SIGNAL_R_ERROR: 'SIGNAL_R_ERROR',
  UNKNOWN_ERROR: 'UNKNOWN_ERROR',
  PERMISSION_ERROR: 'PERMISSION_ERROR',
  HOOK_ERROR: 'HOOK_ERROR'
}

export const DEFAULT_DROPDOWN = [
  {
    text: '',
    value: ''
  }
]
