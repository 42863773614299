import { HubConnectionBuilder, HttpTransportType } from '@microsoft/signalr'
import { getSignalREndpoint } from '@utils/apis'
import { signalRErrorLogger } from '@utils/datadog'

const getSignalRConnection = () => {
  const config = {
    skipNegotiation: true,
    transport: HttpTransportType.WebSockets
  }
  const endpoint = getSignalREndpoint()
  if (endpoint === '') {
    signalRErrorLogger('No endpoint attached to the SignalR connection')
  }
  const connection = new HubConnectionBuilder()
    .withUrl(`${endpoint}/will-trace-hub`, process.env.NODE_ENV === 'development' ? config : {})
    .withAutomaticReconnect()
    .build()
  return connection
}

export { getSignalRConnection }
