import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ICommonSearch } from '@models/common'
import { IQueryReceivedLostWillRequestsAsync, IReceivedLostWillRequests } from '@models/lostWills'
import { receivedLostWillIntial } from '@store/initials/lostWillIntial'

const receivedWillSlice = createSlice({
  name: 'receivedLostWillSlice',
  initialState: receivedLostWillIntial,
  reducers: {
    savingReceivedLostWillRequest: (
      state: ICommonSearch<IQueryReceivedLostWillRequestsAsync, IReceivedLostWillRequests[]>,
      action: PayloadAction<ICommonSearch<IQueryReceivedLostWillRequestsAsync, IReceivedLostWillRequests[]>>
    ) => {
      Object.assign(state, action.payload)
    },
    resetReceivedLostWillRequest: () => receivedLostWillIntial
  }
})

export const { savingReceivedLostWillRequest, resetReceivedLostWillRequest } = receivedWillSlice.actions

export default receivedWillSlice.reducer
