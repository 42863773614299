import { createContext, useContext, useMemo } from 'react'
import useSignalR, { SignalREventConfig } from '@hooks/useSignalR'
import { useMarkdownText } from '@hooks/useMarkdownText'
import { MarkdownKey } from '@constants/markdown'
import { SIGNALR_EVENT } from '@constants/index'

interface Props {
  getText: (key: MarkdownKey, dynamicVariables?: Record<string, any>) => string
  getMedia: (key: MarkdownKey) => string | React.ReactNode
  loading: boolean
}

const MarkDownTextcontext = createContext({
  getText: (key: MarkdownKey, dynamicVariables?: Record<string, any>) => key.toString(),
  getMedia: (key: MarkdownKey) => key.toString(),
  loading: false
} as Props)

export const MarkDownTextProvider = ({ children }: React.PropsWithChildren) => {
  const { fetchMarkdownText, getText, getMedia, loading } = useMarkdownText()
  const eventConfigs: SignalREventConfig[] = [
    {
      type: 'on',
      eventName: {
        name: SIGNALR_EVENT.TEXT_RESOURCES_UPDATED
      },
      callback: () => {
        !loading && fetchMarkdownText()
      }
    }
  ]
  useSignalR(eventConfigs)
  const value = useMemo(() => ({ getText, getMedia, loading }), [getText, getMedia])
  return <MarkDownTextcontext.Provider value={value}>{children}</MarkDownTextcontext.Provider>
}

export const useMarkdownContext = () => useContext(MarkDownTextcontext)
