import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ICommonSearch } from '@models/common'
import { noticeInitial } from '@store/initials/noticeInitial'
import { INotice } from '@models/notices'
import { NoticesType, SearchNoticeTypes } from '@store/types/noticeTypes'
import { SelectOption } from '@infotrack/zenith-ui'

const noticeSlice = createSlice({
  name: 'notice',
  initialState: noticeInitial,
  reducers: {
    savingSearchNotices: (
      state: NoticesType,
      { payload }: PayloadAction<ICommonSearch<SearchNoticeTypes, INotice[]>>
    ) => {
      return { ...state, searchData: { ...state.searchData, ...payload } }
    },
    savingListSource: (state: NoticesType, { payload }: PayloadAction<SelectOption[]>) => {
      return { ...state, listSource: [...noticeInitial.listSource, ...payload] }
    },
    resetSearchNotices: (state: NoticesType) => {
      return { ...state, searchData: { ...state.searchData, ...noticeInitial.searchData } }
    },
    savingListPostCode: (state: any, { payload }: PayloadAction<SelectOption[]>) => {
      return { ...state, listPostCode: [...noticeInitial.listPostCode, ...payload] }
    }
  }
})

export const { savingSearchNotices, resetSearchNotices, savingListSource, savingListPostCode } =
  noticeSlice.actions

export default noticeSlice.reducer
