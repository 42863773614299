import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ICommonSearch } from '@models/common'
import { IMyLostWillRequests, IQueryMyLostWillRequests} from '@models/lostWills'
import { MyLostWillIntial } from '@store/initials/lostWillIntial'

const lostWillSlice = createSlice({
  name: 'lostWill',
  initialState: MyLostWillIntial,
  reducers: {
    savingMyLostWillRequest: (
      state: ICommonSearch<IQueryMyLostWillRequests, IMyLostWillRequests[]>,
      action: PayloadAction<ICommonSearch<IQueryMyLostWillRequests, IMyLostWillRequests[]>>
    ) => {
      Object.assign(state, action.payload)
    },
    resetMyLostWillRequest: () => MyLostWillIntial
  }
})

export const { savingMyLostWillRequest, resetMyLostWillRequest } = lostWillSlice.actions

export default lostWillSlice.reducer
