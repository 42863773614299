import { ICommonSearch } from '@models/common';
import { IMyLostWillRequests, IQueryMyLostWillRequests, IQueryReceivedLostWillRequestsAsync, IReceivedLostWillRequests } from '@models/lostWills';
export const MyLostWillIntial: ICommonSearch<IQueryMyLostWillRequests, IMyLostWillRequests[]>  = {
  search: {},
  data: [],
  currentPage: 0,
  totalPage: 0
}

export const receivedLostWillIntial: ICommonSearch<IQueryReceivedLostWillRequestsAsync, IReceivedLostWillRequests[]>  = {
  search: {},
  data: [],
  currentPage: 0,
  totalPage: 0
}