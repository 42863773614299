import { chargeableSearchWillsInitial } from '@store/initials/chargeableSearchWillsInitial'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { TChargeableSearch } from '@store/types/chargeableSearchWills'
const chargeableSearchWillsSlice = createSlice({
  name: 'chargeableSearchWills',
  initialState: chargeableSearchWillsInitial,
  reducers: {
    savingSearchWills: (state: TChargeableSearch, action: PayloadAction<any>) => {
      const {
        payload: {
          search,
          data: { exactWills, otherWills },
          parentOrderId
        }
      } = action
      return {
        ...state,
        search,
        data: {
          exactWills: exactWills,
          otherWills: otherWills
        },
        parentOrderId
      }
    },
    resetSearchWills: () => chargeableSearchWillsInitial
  }
})

export const { savingSearchWills, resetSearchWills } = chargeableSearchWillsSlice.actions

export default chargeableSearchWillsSlice.reducer
