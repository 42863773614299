import { UserTypes } from '@store/types/userTypes'

export const userInitial: UserTypes = {
  isFirstLogin: false,
  leapUserId: null,
  typeUser: {
    pmsActiveType: '',
    isNotificationSetup: false
  },
  userName: '',
  isAcceptedTermAndCondition: false
}
