import { NoticeType } from '@constants/index'
import { ICommonSearch } from '@models/common'
import { INoticeMatches, IQueryNoticeMatches } from '@models/matches'

export const matchInitial: ICommonSearch<IQueryNoticeMatches, INoticeMatches[]> = {
  search: {
    clientname: '',
    showDismissNotices: false,
    noticeType: NoticeType.ALL
  },
  data: [],
  currentPage: 0,
  totalPage: 0
}
