import { SelectOption } from '@infotrack/zenith-ui'

export const SORTING_VALUE = {
  DEFAULT: null,
  ASCENDING: false,
  DESCENDING: true
}

export const PUBLISH_DATE_RANGE: SelectOption[] = [
  {
    text: '7 days',
    value: 7
  },
  {
    text: '14 days',
    value: 14
  },
  {
    text: '30 days',
    value: 30
  },
  {
    text: '60 days',
    value: 60
  }
]

export const NEARBY_LOCATION_RANGE: SelectOption[] = [
  {
    text: '+3km',
    value: 3
  },
  {
    text: '+5km',
    value: 5
  },
  {
    text: '+7km',
    value: 7
  },
  {
    text: '+10km',
    value: 10
  }
]
