import { useEffect } from 'react'
import { HubConnection } from '@microsoft/signalr'

import { getSignalRConnection } from '@services/signalR'
import { apiErrorLogger } from '@utils/datadog'

export type SignalREventConfig = {
  type: 'on' | 'invoke'
  eventName: { name: string; data?: any }
  callback?: () => void
}

export const handleSignalREvents = (
  connection: HubConnection,
  eventConfigs: SignalREventConfig[]
) => {
  eventConfigs.forEach(({ type, eventName, callback }) => {
    const { name, data } = eventName
    const event = typeof eventName === 'string' ? eventName : name
    connection.onreconnected(() => {
      console.info(`Reconnected to ${event}`)
      callback?.()
    })
    if (type === 'on') {
      connection.on(event, (message: string) => {
        console.info(`Received message for ${event}:`, message)
        callback?.()
      })
    } else if (type === 'invoke') {
      const { name, data } = eventName
      connection
        .invoke(name, data)
        .then(response => {
          console.info(`Invoke response for ${event}:`, response)
          callback?.()
        })
        .catch(error => {
          apiErrorLogger(`Error invoking ${event}:`, error)
        })
    }
  })
}

const useSignalR = (eventConfigs: SignalREventConfig[]) => {
  useEffect(() => {
    const connection = getSignalRConnection()

    const startConnection = async () => {
      try {
        if (connection) {
          console.info('SignalR connection stop')
          await connection.stop() // Close the existing connection
        }

        await connection.start()
        console.info('SignalR connection established')

        handleSignalREvents(connection, eventConfigs)
      } catch (err) {
        apiErrorLogger('Error establishing connection:', err)
      }
    }

    startConnection()

    return () => {
      connection.stop() // Close the connection when unmounting
    }
  }, [])
}

export default useSignalR
