import { APP_URL } from '@constants/index'
import { resetCommon } from '@store/slice/common'
import { resetSearchNoticesMatch } from '@store/slice/match'
import { resetMyWills } from '@store/slice/myWill'
import { resetSearchNotices } from '@store/slice/notice'
import { resetSetting } from '@store/slice/setting'
import { resetUserInfo } from '@store/slice/user'
import { resetSearchWills } from '@store/slice/will'

export const getToken = () => {
  const tokenLocalStorage = window.localStorage.getItem('TOKEN')
  return tokenLocalStorage ?? ''
}

export const authHeader = () => {
  return {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: `Bearer ${getToken()}`
  }
}

export const logout = (dispatch: any, navigate: any) => {
  window.localStorage.clear()
  window.sessionStorage.clear()
  dispatch(resetSearchNotices())
  dispatch(resetSearchWills())
  dispatch(resetMyWills())
  dispatch(resetSearchNoticesMatch())
  dispatch(resetUserInfo())
  dispatch(resetCommon())
  dispatch(resetSetting())
  navigate(APP_URL.BASE)
}
