import { combineReducers } from 'redux'
import totalCountReducer from '@store/slice/totalCountReducer'
import userReducer from '@store/slice/user'
import willReducer from '@store/slice/will'
import myWillReducer from '@store/slice/myWill'
import matchReducer from '@store/slice/match'
import noticeReducer from '@store/slice/notice'
import notificationReducer from '@store/slice/notification'
import settingReducer from '@store/slice/setting'
import commonReducer from '@store/slice/common'
import probateNoticeReducer from '@store/slice/probateNotice'
import silentWill from '@store/slice/silentWill'
import receivedWill from '@store/slice/receivedWill'
import lostWill from '@store/slice/lostWill'
import receivedLostWill from '@store/slice/receivedLostWill'
import historyOrder from '@store/slice/historyOrder'
import featuresReducer from '@store/slice/featureManagement'
import uiElementReducer from '@store/slice/uiElement'
import chargeableSearchWillsReducer from '@store/slice/chargeableSearchWills'

const rootReducer = combineReducers({
  totalCountReducer,
  userReducer,
  notificationReducer,
  noticeReducer,
  myWillReducer,
  matchReducer,
  settingReducer,
  willReducer,
  commonReducer,
  probateNoticeReducer,
  silentWill,
  receivedWill,
  lostWill,
  receivedLostWill,
  historyOrder,
  featuresReducer,
  uiElementReducer,
  chargeableSearchWillsReducer
})

export default rootReducer
