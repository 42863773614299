import { willInitial } from '@store/initials/willInitial'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ICommonSearch } from '@models/common'
import { ISearchWill, IWills } from '@models/wills'

const willSlice = createSlice({
  name: 'will',
  initialState: willInitial,
  reducers: {
    savingSearchWills: (
      state: ICommonSearch<ISearchWill, IWills[]>,
      action: PayloadAction<ICommonSearch<ISearchWill, IWills[]>>
    ) => {
      Object.assign(state, action.payload)
    },
    resetSearchWills: () => willInitial
  }
})

export const { savingSearchWills, resetSearchWills } = willSlice.actions

export default willSlice.reducer
