import { APP_URL } from '@constants/index'
import { lazy } from 'react'
const WrapperSimpleLayout = lazy(() => import('@components/WrapperSimpleLayout'))
const UnderReview = lazy(() => import('@components/UnderReview'))
const ForgotPassword = lazy(() => import('@containers/Authenticate/ForgotPassword'))
const PublicLostWillConfirmation = lazy(
  () => import('@containers/LostWills/PublicLostWillConfirmation')
)
const ResetPassword = lazy(() => import('@containers/Authenticate/ResetPassword'))
const SetPassword = lazy(() => import('@containers/Authenticate/SetPassword'))
const Register = lazy(() => import('@containers/Authenticate/Register'))

const publicRoutes = [
  {
    path: APP_URL.FORGOT_PASSWORD,
    Component: WrapperSimpleLayout,
    children: [
      {
        index: true,
        Component: ForgotPassword
      }
    ]
  },
  {
    path: APP_URL.UNDER_REVIEW,
    Component: WrapperSimpleLayout,
    children: [
      {
        index: true,
        Component: UnderReview
      }
    ]
  },
  {
    path: `${APP_URL.SOCIETY_WILL_LOCATION}/:requestId`,
    Component: WrapperSimpleLayout,
    children: [
      {
        index: true,
        Component: PublicLostWillConfirmation
      }
    ]
  },
  {
    path: APP_URL.RESET_PASSWORD,
    Component: WrapperSimpleLayout,
    children: [
      {
        index: true,
        Component: ResetPassword
      }
    ]
  },
  {
    path: APP_URL.SET_PASSWORD,
    Component: WrapperSimpleLayout,
    children: [
      {
        index: true,
        Component: SetPassword
      }
    ]
  },
  {
    path: APP_URL.REGISTER,
    Component: WrapperSimpleLayout,
    children: [
      {
        index: true,
        Component: Register
      }
    ]
  }
]

export default publicRoutes
