import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { notificationInitial } from '@store/initials/notificationInitial'
import { NotificationTypes } from '@store/types/notificationTypes'

const notificationSlice = createSlice({
  name: 'notification',
  initialState: notificationInitial,
  reducers: {
    setAmountNewNotice(state: NotificationTypes, { payload }: PayloadAction<number>) {
      if (state.amountNewNotice === payload) return
      state.amountNewNotice = payload
    },
    setAmountNewSilent(state: NotificationTypes, { payload }: PayloadAction<number>) {
      if (state.amountNewSilent === payload) return
      state.amountNewSilent = payload
    },
    setAmountNewLostWill(state: NotificationTypes, { payload }: PayloadAction<number>) {
      if (state.amountNewLostWill === payload) return
      state.amountNewLostWill = payload
    }
  }
})

export const { setAmountNewNotice, setAmountNewSilent, setAmountNewLostWill } = notificationSlice.actions

export default notificationSlice.reducer
