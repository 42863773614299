import { FeaturesManagement } from '@models/featuresManagement'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { featuresInitial } from '@store/initials/featuresInitial'

const featureSlice = createSlice({
  name: 'features',
  initialState: featuresInitial,
  reducers: {
    setFeatures: (state, action: PayloadAction<FeaturesManagement[]>) => {
      state.features = [...action.payload]
    }
  }
})

export const { setFeatures } = featureSlice.actions

export default featureSlice.reducer
