// Rule
// This is **bold** text.
// This is [link](https://www.wikipedia.org/)
// MarkdownKey Convention
// ${RouterName}_${ComponentName}_${ElementName}_${ElementDescription}

export enum MarkdownKey {
  ONBOARDING_ONBOARDING_HERO_TITLE = 'ONBOARDING_ONBOARDING_HERO_TITLE',
  ONBOARDING_ONBOARDING_DISCLAIMER_1ST = 'ONBOARDING_ONBOARDING_DISCLAIMER_1ST',
  ONBOARDING_ONBOARDING_DISCLAIMER_2ND = 'ONBOARDING_ONBOARDING_DISCLAIMER_2ND',
  ONBOARDING_ONBOARDING_DISCLAIMER_3RD = 'ONBOARDING_ONBOARDING_DISCLAIMER_3RD',
  ONBOARDING_ONBOARDING_SUBCRIBE_CHECKBOX = 'ONBOARDING_ONBOARDING_SUBCRIBE_CHECKBOX',
  ONBOARDING_ONBOARDING_SUBCRIBE_LIST_1ST = 'ONBOARDING_ONBOARDING_SUBCRIBE_LIST_1ST',
  ONBOARDING_ONBOARDING_SUBCRIBE_LIST_2ND = 'ONBOARDING_ONBOARDING_SUBCRIBE_LIST_2ND',
  ONBOARDING_ONBOARDING_CONTENT_ITEM_1ST_TITLE = 'ONBOARDING_ONBOARDING_CONTENT_ITEM_1ST_TITLE',
  ONBOARDING_ONBOARDING_CONTENT_ITEM_1ST_DESC = 'ONBOARDING_ONBOARDING_CONTENT_ITEM_1ST_DESC',
  ONBOARDING_ONBOARDING_CONTENT_ITEM_2ND_TITLE = 'ONBOARDING_ONBOARDING_CONTENT_ITEM_2ND_TITLE',
  ONBOARDING_ONBOARDING_CONTENT_ITEM_2ND_DESC = 'ONBOARDING_ONBOARDING_CONTENT_ITEM_2ND_DESC',
  ONBOARDING_ONBOARDING_CONTENT_ITEM_3RD_TITLE = 'ONBOARDING_ONBOARDING_CONTENT_ITEM_3RD_TITLE',
  ONBOARDING_ONBOARDING_CONTENT_ITEM_3RD_DESC = 'ONBOARDING_ONBOARDING_CONTENT_ITEM_3RD_DESC',
  ONBOARDING_ONBOARDING_NOTE = 'ONBOARDING_ONBOARDING_NOTE',
  ONBOARDING_ONBOARDING_NOTE_2 = 'ONBOARDING_ONBOARDING_NOTE_2',
  ONBOARDING_ONBOARDING_BTN_SUBSCRIBE = 'ONBOARDING_ONBOARDING_BTN_SUBSCRIBE',

  WILL_WILLS_SEARCH_FORM_GIVEN_NAME_INPUT_PLACEHOLDER = 'WILL_WILLS_SEARCH_FORM_GIVEN_NAME_INPUT_PLACEHOLDER',
  WILL_WILLS_SEARCH_FORM_GIVEN_NAME_INPUT_LABEL = 'WILL_WILLS_SEARCH_FORM_GIVEN_NAME_INPUT_LABEL',
  WILL_WILLS_SEARCH_FORM_LAST_NAME_INPUT_LABEL = 'WILL_WILLS_SEARCH_FORM_LAST_NAME_INPUT_LABEL',
  WILL_WILLS_SEARCH_FORM_LAST_NAME_INPUT_PLACEHOLDER = 'WILL_WILLS_SEARCH_FORM_LAST_NAME_INPUT_PLACEHOLDER',
  WILL_WILLS_SEARCH_FORM_DATE_OF_BIRTH_INPUT_LABEL = 'WILL_WILLS_SEARCH_FORM_DATE_OF_BIRTH_INPUT_LABEL',
  WILL_WILLS_SEARCH_SAMPLE_REPORT_BUTTON_LABEL = 'WILL_WILLS_SEARCH_SAMPLE_REPORT_BUTTON_LABEL',
  WILL_WILLS_SEARCHING_RESULT_EXACT_MATCH_LABEL = 'WILL_WILLS_SEARCHING_RESULT_EXACT_MATCH_LABEL',
  WILL_WILLS_SEARCHING_RESULT_NO_EXACT_MATCH_LABEL = 'WILL_WILLS_SEARCHING_RESULT_NO_EXACT_MATCH_LABEL',
  WILL_WILLS_SEARCHING_SIMILAR_RESULT_MATCH_LABEL = 'WILL_WILLS_SEARCHING_SIMILAR_RESULT_MATCH_LABEL',
  WILL_WILLS_SEARCHING_NO_SIMILAR_RESULT_MATCH_LABEL = 'WILL_WILLS_SEARCHING_NO_SIMILAR_RESULT_MATCH_LABEL',
  WILL_WILLS_NOT_FOUND_ILLUSTRATION_NAME = 'WILL_WILLS_NOT_FOUND_ILLUSTRATION_NAME',
  WILL_WILLS_SAMPLE_RESULT = 'WILL_WILLS_SAMPLE_RESULT',

  WILL_ORDER_TRACKING_TITLE = 'WILL_ORDER_TRACKING_TITLE',
  WILL_TABS_ORDER_TRACKING_LABEL_WILL = 'WILL_TABS_ORDER_TRACKING_LABEL_WILL',
  WILL_TABS_ORDER_TRACKING_LABEL_LOCATION = 'WILL_TABS_ORDER_TRACKING_LABEL_LOCATION',
  WILL_TABS_ORDER_TRACKING_LABEL_INFORMATION = 'WILL_TABS_ORDER_TRACKING_LABEL_INFORMATION',
  WILL_TABS_ORDER_TRACKING_LABEL_DEATH_PROBATE = 'WILL_TABS_ORDER_TRACKING_LABEL_DEATH_PROBATE',
  WILL_TABS_ORDER_TRACKING_LABEL_ORDER_HISTORY = 'WILL_TABS_ORDER_TRACKING_LABEL_ORDER_HISTORY',

  WILL_TABLE_WILLS_PLACEHOLDER_TITLE = 'WILL_TABLE_WILLS_PLACEHOLDER_TITLE',
  WILL_TABLE_WILLS_PLACEHOLDER_SUBTITLE = 'WILL_TABLE_WILLS_PLACEHOLDER_SUBTITLE',
  WILL_TABLE_WILLS_REQUEST_MISSING_WILL_BUTTON_LABEL = 'WILL_TABLE_WILLS_REQUEST_MISSING_WILL_BUTTON_LABEL',
  WILL_TABLE_WILLS_NO_RESULT_FOUND_MESSAGE_PART_1 = 'WILL_TABLE_WILLS_NO_RESULT_FOUND_MESSAGE_PART_1',
  WILL_TABLE_WILLS_NO_RESULT_FOUND_MESSAGE_PART_2 = 'WILL_TABLE_WILLS_NO_RESULT_FOUND_MESSAGE_PART_2',
  WILL_TABLE_WILLS_DRAWER_TITLE = 'WILL_TABLE_WILLS_DRAWER_TITLE',

  WILL_WILL_DETAIL_TABLE_TITLE = 'WILL_WILL_DETAIL_TABLE_TITLE',
  WILL_WILL_DETAIL_TABLE_PLACEHOLDER_TITLE = 'WILL_WILL_DETAIL_TABLE_PLACEHOLDER_TITLE',
  WILL_WILL_DETAIL_TABLE_COL_NAME = 'WILL_WILL_DETAIL_TABLE_COL_NAME',
  WILL_WILL_DETAIL_TABLE_COL_OTHER_NAME = 'WILL_WILL_DETAIL_TABLE_COL_OTHER_NAME',
  WILL_WILL_DETAIL_TABLE_COL_WILL_DATE = 'WILL_WILL_DETAIL_TABLE_COL_WILL_DATE',
  WILL_WILL_DETAIL_TABLE_COL_LAW_FIRM = 'WILL_WILL_DETAIL_TABLE_COL_NAME',
  WILL_WILL_DETAIL_TABLE_COL_ACTION_HIDDEN_TEXT = 'WILL_WILL_DETAIL_TABLE_COL_ACTION_HIDDEN_TEXT',
  WILL_WILL_DETAIL_TABLE_COL_ACTION_SHOWED_TEXT = 'WILL_WILL_DETAIL_TABLE_COL_ACTION_SHOWED_TEXT',
  WILL_WILL_DETAIL_FORM_WILL_NAME_LABEL_POA = 'WILL_WILL_DETAIL_FORM_WILL_NAME_LABEL_POA',
  WILL_WILL_DETAIL_FORM_WILL_NAME_LABEL_BENEFICIARY = 'WILL_WILL_DETAIL_FORM_WILL_NAME_LABEL_BENEFICIARY',
  WILL_WILL_DETAIL_FORM_WILL_NAME_LABEL_EG = 'WILL_WILL_DETAIL_FORM_WILL_NAME_LABEL_EG',
  WILL_WILL_DETAIL_FORM_CLIENT_NUMBER = 'WILL_WILL_DETAIL_FORM_CLIENT_NUMBER',
  WILL_WILL_DETAIL_FORM_OTHER_NAME = 'WILL_WILL_DETAIL_FORM_OTHER_NAME',
  WILL_WILL_DETAIL_FORM_WILL_DATE = 'WILL_WILL_DETAIL_FORM_WILL_DATE',
  WILL_WILL_DETAIL_FORM_DATE_OF_BIRTH = 'WILL_WILL_DETAIL_FORM_DATE_OF_BIRTH',
  WILL_WILL_DETAIL_FORM_PLACE_OF_BIRTH = 'WILL_WILL_DETAIL_FORM_PLACE_OF_BIRTH',
  WILL_WILL_DETAIL_FORM_COUNTRY_OF_BIRTH = 'WILL_WILL_DETAIL_FORM_COUNTRY_OF_BIRTH',
  WILL_WILL_DETAIL_FORM_LAW_FIRM = 'WILL_WILL_DETAIL_FORM_LAW_FIRM',
  WILL_WILL_DETAIL_FORM_LATEST_WILL_VERSION = 'WILL_WILL_DETAIL_FORM_LATEST_WILL_VERSION',
  WILL_WILL_DETAIL_FORM_DATE_OF_DEATH = 'WILL_WILL_DETAIL_FORM_DATE_OF_DEATH',
  WILL_WILL_DETAIL_FORM_WILL_LOCATION = 'WILL_WILL_DETAIL_FORM_WILL_LOCATION',
  WILL_WILL_DETAIL_FORM_EXECUTOR = 'WILL_WILL_DETAIL_FORM_EXECUTOR',

  WILL_MISSING_WILL_REQUEST_FORM_TITLE = 'WILL_MISSING_WILL_REQUEST_FORM_TITLE',
  WILL_MISSING_WILL_REQUEST_FORM_INTERNAL_SEARCH_OPTION = 'WILL_MISSING_WILL_REQUEST_FORM_INTERNAL_SEARCH_OPTION',
  WILL_MISSING_WILL_REQUEST_FORM_LOCAL_SEARCH_OPTION = 'WILL_MISSING_WILL_REQUEST_FORM_LOCAL_SEARCH_OPTION',
  WILL_MISSING_WILL_REQUEST_FORM_TRUSTEE_SEARCH_OPTION = 'WILL_MISSING_WILL_REQUEST_FORM_TRUSTEE_SEARCH_OPTION',
  WILL_MISSING_WILL_REQUEST_FORM_LODGE_SEARCH_OPTION = 'WILL_MISSING_WILL_REQUEST_FORM_LODGE_SEARCH_OPTION',
  WILL_MISSING_WILL_REQUEST_FORM_LAW_SOCIETY_SEARCH_OPTION = 'WILL_MISSING_WILL_REQUEST_FORM_LAW_SOCIETY_SEARCH_OPTION',
  WILL_MISSING_WILL_REQUEST_FORM_SEARCH_TYPE_LABEL = 'WILL_MISSING_WILL_REQUEST_FORM_SEARCH_TYPE_LABEL',
  WILL_MISSING_WILL_REQUEST_FORM_FIRST_NAME_LABEL = 'WILL_MISSING_WILL_REQUEST_FORM_FIRST_NAME_LABEL',
  WILL_MISSING_WILL_REQUEST_FORM_MIDDLE_NAME_LABEL = 'WILL_MISSING_WILL_REQUEST_FORM_MIDDLE_NAME_LABEL',
  WILL_MISSING_WILL_REQUEST_FORM_LAST_NAME_LABEL = 'WILL_MISSING_WILL_REQUEST_FORM_LAST_NAME_LABEL',
  WILL_MISSING_WILL_REQUEST_FORM_DATE_OF_BIRTH_LABEL = 'WILL_MISSING_WILL_REQUEST_FORM_DATE_OF_BIRTH_LABEL',
  WILL_MISSING_WILL_REQUEST_FORM_DATE_OF_DEATH_LABEL = 'WILL_MISSING_WILL_REQUEST_FORM_DATE_OF_DEATH_LABEL',
  WILL_MISSING_WILL_REQUEST_FORM_ADDRESS_LABEL = 'WILL_MISSING_WILL_REQUEST_FORM_ADDRESS_LABEL',
  WILL_MISSING_WILL_REQUEST_FORM_POSTCODE_LABEL = 'WILL_MISSING_WILL_REQUEST_FORM_POSTCODE_LABEL',
  WILL_MISSING_WILL_REQUEST_FORM_NOTICE_REFERENCE_LABEL = 'WILL_MISSING_WILL_REQUEST_FORM_NOTICE_REFERENCE_LABEL',
  WILL_MISSING_WILL_REQUEST_FORM_DEATH_CERTIFICATE_LABEL = 'WILL_MISSING_WILL_REQUEST_FORM_DEATH_CERTIFICATE_LABEL',
  WILL_MISSING_WILL_REQUEST_FORM_PERSONAL_DETAIL_LABEL = 'WILL_MISSING_WILL_REQUEST_FORM_PERSONAL_DETAIL_LABEL',
  WILL_MISSING_WILL_REQUEST_FORM_RELATIONSHIP_LABEL = 'WILL_MISSING_WILL_REQUEST_FORM_RELATIONSHIP_LABEL',
  WILL_MISSING_WILL_REQUEST_FORM_REQUESTOR_FIRST_NAME_LABEL = 'WILL_MISSING_WILL_REQUEST_FORM_REQUESTOR_FIRST_NAME_LABEL',
  WILL_MISSING_WILL_REQUEST_FORM_REQUESTOR_LAST_NAME_LABEL = 'WILL_MISSING_WILL_REQUEST_FORM_REQUESTOR_LAST_NAME_LABEL',
  WILL_MISSING_WILL_REQUEST_FORM_REQUESTOR_EMAIL_LABEL = 'WILL_MISSING_WILL_REQUEST_FORM_REQUESTOR_EMAIL_LABEL',
  WILL_MISSING_WILL_REQUEST_FORM_REQUESTOR_PHONE_LABEL = 'WILL_MISSING_WILL_REQUEST_FORM_REQUESTOR_PHONE_LABEL',

  WILL_MY_WILLS_TITLE = 'WILL_MY_WILLS_TITLE',
  WILL_MY_WILLS_NOTIFICATION_TITLE = 'WILL_MY_WILLS_NOTIFICATION_TITLE',
  WILL_MY_WILLS_NOTIFICATION_DESC = 'WILL_MY_WILLS_NOTIFICATION_DESC',
  WILL_MY_WILLS_NOTIFICATION_SHOW_ALL_LABEL = 'WILL_MY_WILLS_NOTIFICATION_SHOW_ALL_LABEL',
  WILL_MY_WILLS_NOTIFICATION_HIDE_ALL_LABEL = 'WILL_MY_WILLS_NOTIFICATION_HIDE_ALL_LABEL',
  WILL_MY_WILLS_EXPORT_WILL_BUTTON_LABEL = 'WILL_MY_WILLS_EXPORT_WILL_BUTTON_LABEL',
  WILL_MY_WILLS_ADD_WILL_BUTTON_LABEL = 'WILL_MY_WILLS_ADD_WILL_BUTTON_LABEL',
  WILL_MY_WILLS_EDIT_WILL_BUTTON_LABEL = 'WILL_MY_WILLS_EDIT_WILL_BUTTON_LABEL',
  WILL_MY_WILLS_FILTER_VISIBLE_WILLS_DROPDOWN_OPTION_LABEL = 'WILL_MY_WILLS_FILTER_VISIBLE_WILLS_DROPDOWN_OPTION_LABEL',
  WILL_MY_WILLS_FILTER_HIDDEN_WILLS_DROPDOWN_OPTION_LABEL = 'WILL_MY_WILLS_FILTER_HIDDEN_WILLS_DROPDOWN_OPTION_LABEL',
  WILL_MY_WILLS_FILTER_DATE_OF_BIRTH_PLACEHOLDER = 'WILL_MY_WILLS_FILTER_DATE_OF_BIRTH_PLACEHOLDER',
  WILL_MY_WILLS_TABLE_COL_CLIENT_NAME = 'WILL_MY_WILLS_TABLE_COL_CLIENT_NAME',
  WILL_MY_WILLS_TABLE_COL_DATE_OF_BIRTH = 'WILL_MY_WILLS_TABLE_COL_DATE_OF_BIRTH',
  WILL_MY_WILLS_TABLE_COL_DATE_OF_BIRTH_TOOLTIP = 'WILL_MY_WILLS_TABLE_COL_DATE_OF_BIRTH_TOOLTIP',
  WILL_MY_WILLS_TABLE_COL_WILL_DATE = 'WILL_MY_WILLS_TABLE_COL_WILL_DATE',
  WILL_MY_WILLS_TABLE_COL_WILL_DATE_TOOLTIP = 'WILL_MY_WILLS_TABLE_COL_WILL_DATE_TOOLTIP',
  WILL_MY_WILLS_TABLE_COL_LAST_UPDATE = 'WILL_MY_WILLS_TABLE_COL_LAST_UPDATE',
  WILL_MY_WILLS_TABLE_COL_VISIBILITY = 'WILL_MY_WILLS_TABLE_COL_VISIBILITY',
  WILL_MY_WILLS_TABLE_COL_VISIBILITY_TOOLTIP = 'WILL_MY_WILLS_TABLE_COL_VISIBILITY_TOOLTIP',
  WILL_MY_WILLS_DELETE_MODEL_CONTENT = 'WILL_MY_WILLS_DELETE_MODEL_CONTENT',
  WILL_MY_WILLS_DRAWER_TITLE = 'WILL_MY_WILLS_DRAWER_TITLE',

  WILL_SILENT_SEARCH_REVIEW_MODAL_COMMON_TEXT = 'WILL_SILENT_SEARCH_REVIEW_MODAL_COMMON_TEXT',
  WILL_SILENT_SEARCH_REVIEW_MODAL_REST_TEXT = 'WILL_SILENT_SEARCH_REVIEW_MODAL_REST_TEXT',
  WILL_SEARCH_RESULT_OUTCOME_LABEL = 'WILL_SEARCH_RESULT_OUTCOME_LABEL',

  NOTICE_TABLE_COL_NAME = 'NOTICE_TABLE_COL_NAME',
  NOTICE_TABLE_COL_OTHER_NAME = 'NOTICE_TABLE_COL_OTHER_NAME',
  NOTICE_TABLE_COL_AGE = 'NOTICE_TABLE_COL_AGE',
  NOTICE_TABLE_COL_DATE_OF_BIRTH = 'NOTICE_TABLE_COL_DATE_OF_BIRTH',
  NOTICE_TABLE_COL_DATE_OF_DEATH = 'NOTICE_TABLE_COL_DATE_OF_DEATH',
  NOTICE_TABLE_COL_NOTICE_PUBLISH_DATE = 'NOTICE_TABLE_COL_NOTICE_PUBLISH_DATE',
  NOTICE_TABLE_COL_CATEGORY = 'NOTICE_TABLE_COL_CATEGORY',
  NOTICE_TALBE_COL_SOURCE = 'NOTICE_TALBE_COL_SOURCE',
  NOTICE_TABLE_COL_ACTION = 'NOTICE_TABLE_COL_ACTION',
  NOTICE_ADVANCED_SEARCH_FORM_NAME_LABEL = 'NOTICE_ADVANCED_SEARCH_FORM_NAME_LABEL',
  NOTICE_ADVANCED_SEARCH_FORM_NAME_PLACEHOLDER = 'NOTICE_ADVANCED_SEARCH_FORM_NAME_PLACEHOLDER',
  NOTICE_ADVANCED_SEARCH_FORM_PUBLISH_DATE = 'NOTICE_ADVANCED_SEARCH_FORM_PUBLISH_DATE',
  NOTICE_ADVANCED_SEARCH_FORM_DATE_RANGE = 'NOTICE_ADVANCED_SEARCH_FORM_DATE_RANGE',
  NOTICE_ADVANCED_SEARCH_FORM_POST_CODE_LABEL = 'NOTICE_ADVANCED_SEARCH_FORM_POST_CODE_LABEL',
  NOTICE_ADVANCED_SEARCH_FORM_POST_CODE_PLACEHOLDER = 'NOTICE_ADVANCED_SEARCH_FORM_POST_CODE_PLACEHOLDER',
  NOTICE_ADVANCED_SEARCH_FORM_SEARCH_RADIUS_LABEL = 'NOTICE_ADVANCED_SEARCH_FORM_SEARCH_RADIUS_LABEL',
  NOTICE_ADVANCED_SEARCH_FORM_PUBLISH_SOURCE = 'NOTICE_ADVANCED_SEARCH_FORM_PUBLISH_SOURCE',
  NOTICE_ADVANCED_SEARCH_FORM_CLEAR_ALL_BUTTON_LABEL = 'NOTICE_ADVANCED_SEARCH_FORM_CLEAR_ALL_BUTTON_LABEL',
  NOTICE_REGULAR_SEARCH_BUTTON_LABEL = 'NOTICE_REGULAR_SEARCH_BUTTON_LABEL',
  NOTICE_SEARCH_BUTTON_LABEL = 'NOTICE_SEARCH_BUTTON_LABEL',
  NOTICE_REGULAR_SEARCH_INPUT_PLACEHOLDER = 'NOTICE_REGULAR_SEARCH_INPUT_PLACEHOLDER',
  NOTICE_REGULAR_SEARCH_TEXT_HELPER = 'NOTICE_REGULAR_SEARCH_TEXT_HELPER',
  NOTICE_ADVANCED_SEARCH_BUTTON_LABEL = 'NOTICE_ADVANCED_SEARCH_BUTTON_LABEL',
  NOTICE_ADVANCED_SEARCH_BUTTON_TOOLTIP_LABEL = 'NOTICE_ADVANCED_SEARCH_BUTTON_TOOLTIP_LABEL',
  NOTICE_ADVANCED_SEARCH_FORM_PUBLISH_SOURCE_LIMIT_TITLE = 'NOTICE_ADVANCED_SEARCH_FORM_PUBLISH_SOURCE_LIMIT_TITLE',
  NOTICE_PLACE_ORDER_BUTTON_LABEL = 'NOTICE_PLACE_ORDER_BUTTON_LABEL',
  NOTICE_NOTICE_DETAILS_DRAWER_TITLE = 'NOTICE_NOTICE_DETAILS_DRAWER_TITLE',
  NOTICE_NOTICE_DETAILS_FORM_NAME_LABEL = 'NOTICE_NOTICE_DETAILS_FORM_NAME_LABEL',
  NOTICE_NOTICE_DETAILS_FORM_OTHER_NAME_LABEL = 'NOTICE_NOTICE_DETAILS_FORM_OTHER_NAME_LABEL',
  NOTICE_NOTICE_DETAILS_FORM_AGE_LABEL = 'NOTICE_NOTICE_DETAILS_FORM_AGE_LABEL',
  NOTICE_NOTICE_DETAILS_FORM_DATE_OF_BIRTH_LABEL = 'NOTICE_NOTICE_DETAILS_FORM_DATE_OF_BIRTH_LABEL',
  NOTICE_NOTICE_DETAILS_FORM_DATE_OF_DEATH_LABEL = 'NOTICE_NOTICE_DETAILS_FORM_DATE_OF_DEATH_LABEL',
  NOTICE_NOTICE_DETAILS_FORM_NOTICE_PUBLISH_DATE_LABEL = 'NOTICE_NOTICE_DETAILS_FORM_NOTICE_PUBLISH_DATE_LABEL',
  NOTICE_NOTICE_DETAILS_FORM_SOURCE_LABEL = 'NOTICE_NOTICE_DETAILS_FORM_SOURCE_LABEL',
  NOTICE_NOTICE_DETAILS_TABLE_COL_NAME = 'NOTICE_NOTICE_DETAILS_TABLE_COL_NAME',
  NOTICE_NOTICE_DETAILS_TABLE_COL_PUBLISH_DATE = 'NOTICE_NOTICE_DETAILS_TABLE_COL_PUBLISH_DATE',
  NOTICE_NOTICE_DETAILS_TABLE_COL_PUBLISH_SOURCE = 'NOTICE_NOTICE_DETAILS_TABLE_COL_PUBLISH_SOURCE',
  NOTICE_NOTICE_SEARCH_RESULT_OUTCOME_LABEL = 'NOTICE_NOTICE_SEARCH_RESULT_OUTCOME_LABEL',

  PROBATE_NOTICE_TABLE_COL_FIRST_NAME = 'PROBATE_NOTICE_TABLE_COL_FIRST_NAME',
  PROBATE_NOTICE_TABLE_COL_LAST_NAME = 'PROBATE_NOTICE_TABLE_COL_LAST_NAME',
  PROBATE_NOTICE_TABLE_COL_STATE = 'PROBATE_NOTICE_TABLE_COL_STATE',
  PROBATE_NOTICE_TABLE_COL_DATE_OF_BIRTH = 'PROBATE_NOTICE_TABLE_COL_DATE_OF_BIRTH',
  PROBATE_NOTICE_TABLE_COL_WILL_DATE = 'PROBATE_NOTICE_TABLE_COL_WILL_DATE',
  PROBATE_NOTICE_TABLE_COL_PUBLISH_DATE = 'PROBATE_NOTICE_TABLE_COL_PUBLISH_DATE',
  PROBATE_NOTICE_TABLE_COL_EXPIRED_DATE = 'PROBATE_NOTICE_TABLE_COL_EXPIRED_DATE',
  PROBATE_NOTICE_TABLE_COL_ACTION = 'PROBATE_NOTICE_TABLE_COL_ACTION',
  PROBATE_NOTICE_PROBATE_DETAILS_DRAWER_TITLE = 'PROBATE_NOTICE_PROBATE_DETAILS_DRAWER_TITLE',
  PROBATE_NOTICE_PROBATE_DETAILS_FORM_FIRST_NAME_LABEL = 'PROBATE_NOTICE_PROBATE_DETAILS_FORM_FIRST_NAME_LABEL',
  PROBATE_NOTICE_PROBATE_DETAILS_FORM_LAST_NAME_LABEL = 'PROBATE_NOTICE_PROBATE_DETAILS_FORM_LAST_NAME_LABEL',
  PROBATE_NOTICE_PROBATE_DETAILS_FORM_DATE_OF_BIRTH_LABEL = 'PROBATE_NOTICE_PROBATE_DETAILS_FORM_DATE_OF_BIRTH_LABEL',
  PROBATE_NOTICE_PROBATE_DETAILS_FORM_WILL_DATE_LABEL = 'PROBATE_NOTICE_PROBATE_DETAILS_FORM_WILL_DATE_LABEL',
  PROBATE_NOTICE_PROBATE_DETAILS_FORM_EXECUTOR_NAME_LABEL = 'PROBATE_NOTICE_PROBATE_DETAILS_FORM_EXECUTOR_NAME_LABEL',
  PROBATE_NOTICE_PROBATE_DETAILS_FORM_PUBLISHED_DATE_LABEL = 'PROBATE_NOTICE_PROBATE_DETAILS_FORM_PUBLISHED_DATE_LABEL',
  PROBATE_NOTICE_PROBATE_DETAILS_FORM_EXPIRED_DATE_LABEL = 'PROBATE_NOTICE_PROBATE_DETAILS_FORM_EXPIRED_DATE_LABEL',
  PROBATE_NOTICE_PROBATE_DETAILS_FORM_PUBLISHED_BY_LABEL = 'PROBATE_NOTICE_PROBATE_DETAILS_FORM_PUBLISHED_BY_LABEL',
  PROBATE_NOTICE_PROBATE_DETAILS_FORM_COURT_LABEL = 'PROBATE_NOTICE_PROBATE_DETAILS_FORM_COURT_LABEL',
  PROBATE_NOTICE_PROBATE_DETAILS_FORM_LINK_LABEL = 'PROBATE_NOTICE_PROBATE_DETAILS_FORM_LINK_LABEL',
  PROBATE_NOTICE_SEARCH_FORM_FIRST_NAME_LABEL = 'PROBATE_NOTICE_SEARCH_FORM_FIRST_NAME_LABEL',
  PROBATE_NOTICE_SEARCH_FORM_FIRST_NAME_PLACEHOLDER = 'PROBATE_NOTICE_SEARCH_FORM_FIRST_NAME_PLACEHOLDER',
  PROBATE_NOTICE_SEARCH_FORM_LAST_NAME_LABEL = 'PROBATE_NOTICE_SEARCH_FORM_LAST_NAME_LABEL',
  PROBATE_NOTICE_SEARCH_FORM_LAST_NAME_PLACEHOLDER = 'PROBATE_NOTICE_SEARCH_FORM_LAST_NAME_PLACEHOLDER',
  PROBATE_NOTICE_SEARCH_FORM_DATE_OF_DEATH_LABEL = 'PROBATE_NOTICE_SEARCH_FORM_DATE_OF_DEATH_LABEL',
  PROBATE_NOTICE_SEARCH_FORM_DATE_OF_BIRTH_LABEL = 'PROBATE_NOTICE_SEARCH_FORM_DATE_OF_BIRTH_LABEL',
  PROBATE_NOTICE_SEARCH_FORM_STATE_LABEL = 'PROBATE_NOTICE_SEARCH_FORM_STATE_LABEL',
  PROBATE_NOTICE_PLACE_ORDER_BUTTON_LABEL = 'PROBATE_NOTICE_PLACE_ORDER_BUTTON_LABEL',
  PROBATE_NOTICE_SEARCH_RESULT_OUTCOME_LABEL = 'PROBATE_NOTICE_SEARCH_RESULT_OUTCOME_LABEL',

  SETTING_WILL_TRACE_TITLE = 'SETTING_WILL_TRACE_TITLE',
  SETTING_SETTING_BUTTON_LABEL = 'SETTING_SETTING_BUTTON_LABEL',
  SETTING_HOME_BUTTON_LABEL = 'SETTING_HOME_BUTTON_LABEL',
  SETTING_SETTING_LABEL = 'SETTING_SETTING_LABEL',

  SETTING_MENU_NOTIFICATION_LABEL = 'SETTING_MENU_NOTIFICATION_LABEL',
  SETTING_MENU_REGISTER_WILL_LABEL = 'SETTING_MENU_REGISTER_WILL_LABEL',
  SETTING_MENU_PRIVACY_LABEL = 'SETTING_MENU_PRIVACY_LABEL',
  SETTING_MENU_YOUR_DETAILS_LABEL = 'SETTING_MENU_YOUR_DETAILS_LABEL',
  SETTING_MENU_MANAGE_USER_LABEL = 'SETTING_MENU_MANAGE_USER_LABEL',

  SETTING_NOTIFICATION_LABEL = 'SETTING_NOTIFICATION_LABEL',
  SETTING_NOTIFICATION_FORM_EVENT_NOTIFIED_LABEL = 'SETTING_NOTIFICATION_FORM_EVENT_NOTIFIED_LABEL',
  SETTING_NOTIFICATION_FORM_TIME_NOTIFIED_LABEL = 'SETTING_NOTIFICATION_FORM_TIME_NOTIFIED_LABEL',
  SETTING_NOTIFICATION_FORM_TIME_ZONED_NOTIFIED_LABEL = 'SETTING_NOTIFICATION_FORM_TIME_ZONED_NOTIFIED_LABEL',
  SETTING_NOTIFICATION_FORM_DAYS_NOTIFIED_LABEL = 'SETTING_NOTIFICATION_FORM_DAYS_NOTIFIED_LABEL',
  SETTING_NOTIFICATION_NOTICE_SENTENCE = 'SETTING_NOTIFICATION_NOTICE_SENTENCE',
  SETTING_NOTIFICATION_SAVE_BUTTON_LABEL = 'SETTING_NOTIFICATION_SAVE_BUTTON_LABEL',
  SETTING_REGISTER_WILL_TITLE = 'SETTING_REGISTER_WILL_TITLE',

  SETTING_UPLOAD_WILL_TITLE = 'SETTING_UPLOAD_WILL_TITLE',
  SETTING_UPLOAD_WILL_DESC = 'SETTING_UPLOAD_WILL_DESC',
  SETTING_UPLOAD_WILL_CONDITION_TEXT = 'SETTING_UPLOAD_WILL_CONDITION_TEXT',
  SETTING_UPLOAD_WILL_VIEW_CONDITION_BUTTON_LABEL = 'SETTING_UPLOAD_WILL_VIEW_CONDITION_BUTTON_LABEL',
  SETTING_UPLOAD_WILL_DOWNLOAD_TEMPLATE_BUTTON_LABEL = 'SETTING_UPLOAD_WILL_DOWNLOAD_TEMPLATE_BUTTON_LABEL',
  SETTING_UPLOAD_WILL_UPLOAD_SETTING_LABEL = 'SETTING_UPLOAD_WILL_UPLOAD_SETTING_LABEL',
  SETTING_UPLOAD_WILL_UPLOAD_SETTING_RADIO_LABEL = 'SETTING_UPLOAD_WILL_UPLOAD_SETTING_RADIO_LABEL',
  SETTING_UPLOAD_WILL_UPLOAD_SETTING_RADIO_UPDATE_EXISTING_WILL_OPTION = 'SETTING_UPLOAD_WILL_UPLOAD_SETTING_RADIO_UPDATE_EXISTING_WILL_OPTION',
  SETTING_UPLOAD_WILL_UPLOAD_SETTING_RADIO_ADD_NEW_WILL_OPTION = 'SETTING_UPLOAD_WILL_UPLOAD_SETTING_RADIO_ADD_NEW_WILL_OPTION',
  SETTING_UPLOAD_WILL_UPLOAD_SETTING_RADIO_IGNORE_WILL_OPTION = 'SETTING_UPLOAD_WILL_UPLOAD_SETTING_RADIO_IGNORE_WILL_OPTION',
  SETTING_UPLOAD_WILL_SEND_EMAIL_CHECKBOX_LABEL = 'SETTING_UPLOAD_WILL_SEND_EMAIL_CHECKBOX_LABEL',
  SETTING_UPLOAD_WILL_AGREEMENT_CHECKBOX_LABEL = 'SETTING_UPLOAD_WILL_AGREEMENT_CHECKBOX_LABEL',
  SETTING_UPLOAD_WILL_UPLOAD_BUTTON_LABEL = 'SETTING_UPLOAD_WILL_UPLOAD_BUTTON_LABEL',
  SETTING_UPLOAD_WILL_SECURE_PROTOCAL_TITLE = 'SETTING_UPLOAD_WILL_SECURE_PROTOCAL_TITLE',
  SETTING_UPLOAD_WILL_SECURE_PROTOCAL_DESC = 'SETTING_UPLOAD_WILL_SECURE_PROTOCAL_DESC',
  SETTING_UPLOAD_WILL_SECURE_PROTOCAL_BUTTON_LABEL = 'SETTING_UPLOAD_WILL_SECURE_PROTOCAL_BUTTON_LABEL',
  SETTING_UPLOAD_WILL_VIEW_PREVIOUS_UPLOADED_CSV_BUTTON_LABEL = 'SETTING_UPLOAD_WILL_VIEW_PREVIOUS_UPLOADED_CSV_BUTTON_LABEL',
  SETTING_UPLOAD_WILL_TABLE_COL_FILE_NAME = 'SETTING_UPLOAD_WILL_TABLE_COL_FILE_NAME',
  SETTING_UPLOAD_WILL_TABLE_COL_WILLS_UPLOADED = 'SETTING_UPLOAD_WILL_TABLE_COL_WILLS_UPLOADED',
  SETTING_UPLOAD_WILL_TABLE_COL_WILLS_FAILED = 'SETTING_UPLOAD_WILL_TABLE_COL_WILLS_FAILED',
  SETTING_UPLOAD_WILL_TABLE_COL_STATUS = 'SETTING_UPLOAD_WILL_TABLE_COL_STATUS',
  SETTING_UPLOAD_WILL_TABLE_COL_UPLOAD_DATE = 'SETTING_UPLOAD_WILL_TABLE_COL_UPLOAD_DATE',
  SETTING_UPLOAD_WILL_TABLE_COL_FILE_SIZE = 'SETTING_UPLOAD_WILL_TABLE_COL_FILE_SIZE',
  SETTING_UPLOAD_WILL_TABLE_PLACEHOLDER_TITLE = 'SETTING_UPLOAD_WILL_TABLE_PLACEHOLDER_TITLE',
  SETTING_UPLOAD_WILL_DOCUMENT_REQUIRE_TITLE = 'SETTING_UPLOAD_WILL_DOCUMENT_REQUIRE_TITLE',
  SETTING_UPLOAD_WILL_DOCUMENT_REQUIRE_COLUMN_STRUCTURE_SUBTITLE = 'SETTING_UPLOAD_WILL_DOCUMENT_REQUIRE_COLUMN_STRUCTURE_SUBTITLE',
  SETTING_UPLOAD_WILL_DOCUMENT_REQUIRE_WILL_NAME_ITEM = 'SETTING_UPLOAD_WILL_DOCUMENT_REQUIRE_WILL_NAME_ITEM',
  SETTING_UPLOAD_WILL_DOCUMENT_REQUIRE_WILL_DATE_ITEM = 'SETTING_UPLOAD_WILL_DOCUMENT_REQUIRE_WILL_DATE_ITEM',
  SETTING_UPLOAD_WILL_DOCUMENT_REQUIRE_DATE_OF_BIRTH_ITEM = 'SETTING_UPLOAD_WILL_DOCUMENT_REQUIRE_DATE_OF_BIRTH_ITEM',
  SETTING_UPLOAD_WILL_DOCUMENT_REQUIRE_CLIENT_NUMBER_ITEM = 'SETTING_UPLOAD_WILL_DOCUMENT_REQUIRE_CLIENT_NUMBER_ITEM',
  SETTING_UPLOAD_WILL_DOCUMENT_REQUIRE_1ST_OTHER_NAME_ITEM = 'SETTING_UPLOAD_WILL_DOCUMENT_REQUIRE_1ST_OTHER_NAME_ITEM',
  SETTING_UPLOAD_WILL_DOCUMENT_REQUIRE_2ND_OTHER_NAME_ITEM = 'SETTING_UPLOAD_WILL_DOCUMENT_REQUIRE_2ND_OTHER_NAME_ITEM',
  SETTING_UPLOAD_WILL_DOCUMENT_REQUIRE_OTHER_REQUIREMENT_SUBTITLE = 'SETTING_UPLOAD_WILL_DOCUMENT_REQUIRE_OTHER_REQUIREMENT_SUBTITLE',
  SETTING_UPLOAD_WILL_DOCUMENT_REQUIRE_OTHER_REQUIREMENT_1ST_ITEM = 'SETTING_UPLOAD_WILL_DOCUMENT_REQUIRE_OTHER_REQUIREMENT_1ST_ITEM',
  SETTING_UPLOAD_WILL_DOCUMENT_REQUIRE_OTHER_REQUIREMENT_2ND_ITEM = 'SETTING_UPLOAD_WILL_DOCUMENT_REQUIRE_OTHER_REQUIREMENT_2ND_ITEM',
  SETTING_UPLOAD_WILL_DOCUMENT_REQUIRE_OTHER_REQUIREMENT_3RD_ITEM = 'SETTING_UPLOAD_WILL_DOCUMENT_REQUIRE_OTHER_REQUIREMENT_3RD_ITEM',
  SETTING_UPLOAD_WILL_DOCUMENT_REQUIRE_OTHER_REQUIREMENT_GOT_IT_BUTTON_LABEL = 'SETTING_UPLOAD_WILL_DOCUMENT_REQUIRE_OTHER_REQUIREMENT_GOT_IT_BUTTON_LABEL',
  SETTING_UPLOAD_WILL_DOCUMENT_REQUIRE_MEDIA = 'SETTING_UPLOAD_WILL_DOCUMENT_REQUIRE_MEDIA',

  SETTING_HISTORY_UPLOAD_MODAL_TITLE = 'SETTING_HISTORY_UPLOAD_MODAL_TITLE',
  SETTING_HISTORY_UPLOAD_TABLE_COL_FILE_NAME = 'SETTING_HISTORY_UPLOAD_TABLE_COL_FILE_NAME',
  SETTING_HISTORY_UPLOAD_TABLE_COL_WILLS_UPLOADED = 'SETTING_HISTORY_UPLOAD_TABLE_COL_WILLS_UPLOADED',
  SETTING_HISTORY_UPLOAD_TABLE_COL_WILLS_FAILED = 'SETTING_HISTORY_UPLOAD_TABLE_COL_WILLS_FAILED',
  SETTING_HISTORY_UPLOAD_TABLE_COL_STATUS = 'SETTING_HISTORY_UPLOAD_TABLE_COL_STATUS',
  SETTING_HISTORY_UPLOAD_TABLE_COL_UPLOAD_DATE = 'SETTING_HISTORY_UPLOAD_TABLE_COL_UPLOAD_DATE',
  SETTING_HISTORY_UPLOAD_TABLE_COL_FILE_SIZE = 'SETTING_HISTORY_UPLOAD_TABLE_COL_FILE_SIZE',
  SETTING_HISTORY_UPLOAD_TABLE_PLACEHOLDER_TITLE = 'SETTING_HISTORY_UPLOAD_TABLE_PLACEHOLDER_TITLE',

  SETTING_SFTP_CONFIG_TITLE = 'SETTING_SFTP_CONFIG_TITLE',
  SETTING_SFTP_CONFIG_FORM_HOST_LABEL = 'SETTING_SFTP_CONFIG_FORM_HOST_LABEL',
  SETTING_SFTP_CONFIG_FORM_PORT_LABEL = 'SETTING_SFTP_CONFIG_FORM_PORT_LABEL',
  SETTING_SFTP_CONFIG_FORM_USERNAME_LABEL = 'SETTING_SFTP_CONFIG_FORM_USERNAME_LABEL',
  SETTING_SFTP_CONFIG_FORM_PASSWORD_LABEL = 'SETTING_SFTP_CONFIG_FORM_PASSWORD_LABEL',
  SETTING_SFTP_CONFIG_FORM_UPLOAD_DIRECTORY_LABEL = 'SETTING_SFTP_CONFIG_FORM_UPLOAD_DIRECTORY_LABEL',
  SETTING_SFTP_CONFIG_FORM_NOTIFICATION_EMAIL_LABEL = 'SETTING_SFTP_CONFIG_FORM_NOTIFICATION_EMAIL_LABEL',
  SETTING_SFTP_CONFIG_FORM_IS_ACTIVE_LABEL = 'SETTING_SFTP_CONFIG_FORM_IS_ACTIVE_LABEL',
  SETTING_SFTP_CONFIG_FORM_SAVE_BUTTON_LABEL = 'SETTING_SFTP_CONFIG_FORM_SAVE_BUTTON_LABEL',
  SETTING_SFTP_CONFIG_FORM_CHECK_CONNECTION_BUTTON_LABEL = 'SETTING_SFTP_CONFIG_FORM_CHECK_CONNECTION_BUTTON_LABEL',

  SETTING_EMAIL_CONFIG_TITLE = 'SETTING_EMAIL_CONFIG_TITLE',
  SETTING_EMAIL_SYNC_CONFIG_FORM_EMAIL_LABEL = 'SETTING_EMAIL_SYNC_CONFIG_FORM_EMAIL_LABEL',
  SETTING_EMAIL_SYNC_CONFIG_FORM_IS_ENABLE_LABEL = 'SETTING_EMAIL_SYNC_CONFIG_FORM_IS_ENABLE_LABEL',
  SETTING_EMAIL_SYNC_CONFIG_FORM_SAVE_BUTTON_LABEL = 'SETTING_EMAIL_SYNC_CONFIG_FORM_SAVE_BUTTON_LABEL',

  SETTING_FILE_PROCESSING_FILE_UPLOAD_TITLE = 'SETTING_FILE_PROCESSING_FILE_UPLOAD_TITLE',
  SETTING_FILE_PROCESSING_FILE_MINUTE_REMAIN_LABEL = 'SETTING_FILE_PROCESSING_FILE_MINUTE_REMAIN_LABEL',
  SETTING_FILE_PROCESSING_UPLOADED_SUCCESS_TITLE = 'SETTING_FILE_PROCESSING_UPLOADED_SUCCESS_TITLE',
  SETTING_FILE_PROCESSING_UPLOADED_SUCCESS_DESC = 'SETTING_FILE_PROCESSING_UPLOADED_SUCCESS_DESC',
  SETTING_FILE_PROCESSING_UPLOADED_SUCCESS_BUTTON_LABEL = 'SETTING_FILE_PROCESSING_UPLOADED_SUCCESS_BUTTON_LABEL',
  SETTING_FILE_PROCESSING_UPLOADED_FAILED_TITLE = 'SETTING_FILE_PROCESSING_UPLOADED_FAILED_TITLE',
  SETTING_FILE_PROCESSING_UPLOADED_FAILED_BUTTON_LABEL = 'SETTING_FILE_PROCESSING_UPLOADED_FAILED_BUTTON_LABEL',

  SETTING_PRIVACY_TITLE = 'SETTING_PRIVACY_TITLE',
  SETTING_PRIVACY_UNLOCKED_TOGGLE_LABEL = 'SETTING_PRIVACY_UNLOCKED_TOGGLE_LABEL',
  SETTING_PRIVACY_UNLOCKED_TOGGLE_DESC_1ST = 'SETTING_PRIVACY_UNLOCKED_TOGGLE_DESC_1ST',
  SETTING_PRIVACY_UNLOCKED_TOGGLE_DESC_2ND = 'SETTING_PRIVACY_UNLOCKED_TOGGLE_DESC_2ND',
  SETTING_PRIVACY_UNLOCKED_TOGGLE_DESC_2ND_LIST_ITEM_1ST = 'SETTING_PRIVACY_UNLOCKED_TOGGLE_DESC_2ND_LIST_ITEM_1ST',
  SETTING_PRIVACY_UNLOCKED_TOGGLE_DESC_2ND_LIST_ITEM_2ND = 'SETTING_PRIVACY_UNLOCKED_TOGGLE_DESC_2ND_LIST_ITEM_2ND',
  SETTING_PRIVACY_UNLOCKED_TOGGLE_DESC_2ND_LIST_ITEM_3RD = 'SETTING_PRIVACY_UNLOCKED_TOGGLE_DESC_2ND_LIST_ITEM_3RD',
  SETTING_PRIVACY_UNLOCKED_TOGGLE_DESC_2ND_LIST_ITEM_3RD_LIST_ITEM_1ST = 'SETTING_PRIVACY_UNLOCKED_TOGGLE_DESC_2ND_LIST_ITEM_3RD_LIST_ITEM_1ST',
  SETTING_PRIVACY_UNLOCKED_TOGGLE_DESC_2ND_LIST_ITEM_3RD_LIST_ITEM_2ND = 'SETTING_PRIVACY_UNLOCKED_TOGGLE_DESC_2ND_LIST_ITEM_3RD_LIST_ITEM_2ND',
  SETTING_PRIVACY_LOCKED_TOGGLE_LABEL = 'SETTING_PRIVACY_LOCKED_TOGGLE_LABEL',
  SETTING_PRIVACY_LOCKED_TOGGLE_DESC_1ST = 'SETTING_PRIVACY_LOCKED_TOGGLE_DESC_1ST',
  SETTING_PRIVACY_LOCKED_TOGGLE_DESC_2ND = 'SETTING_PRIVACY_LOCKED_TOGGLE_DESC_2ND',
  SETTING_PRIVACY_LOCKED_TOGGLE_DESC_2ND_LIST_ITEM_1ST = 'SETTING_PRIVACY_LOCKED_TOGGLE_DESC_2ND_LIST_ITEM_1ST',
  SETTING_PRIVACY_LOCKED_TOGGLE_DESC_2ND_LIST_ITEM_2ND = 'SETTING_PRIVACY_LOCKED_TOGGLE_DESC_2ND_LIST_ITEM_2ND',
  SETTING_PRIVACY_LOCKED_TOGGLE_DESC_2ND_LIST_ITEM_3RD = 'SETTING_PRIVACY_LOCKED_TOGGLE_DESC_2ND_LIST_ITEM_3RD',
  SETTING_PRIVACY_LOCKED_TOGGLE_DESC_2ND_LIST_ITEM_3RD_LIST_ITEM_1ST = 'SETTING_PRIVACY_LOCKED_TOGGLE_DESC_2ND_LIST_ITEM_3RD_LIST_ITEM_1ST',
  SETTING_PRIVACY_LOCKED_TOGGLE_DESC_2ND_LIST_ITEM_3RD_LIST_ITEM_2ND = 'SETTING_PRIVACY_LOCKED_TOGGLE_DESC_2ND_LIST_ITEM_3RD_LIST_ITEM_2ND',
  SETTING_PRIVACY_CANCEL_WARNING_TITLE = 'SETTING_PRIVACY_CANCEL_WARNING_TITLE',
  SETTING_PRIVACY_CANCEL_WARNING = 'SETTING_PRIVACY_CANCEL_WARNING',
  SETTING_PRIVACY_CANCEL_WARNING_1ST = 'SETTING_PRIVACY_CANCEL_WARNING_1ST',
  SETTING_PRIVACY_CANCEL_WARNING_2ND = 'SETTING_PRIVACY_CANCEL_WARNING_2ND',
  SETTING_PRIVACY_CANCEL_WARNING_3RD = 'SETTING_PRIVACY_CANCEL_WARNING_3RD',
  SETTING_PRIVACY_CANCEL_WARNING_4TH = 'SETTING_PRIVACY_CANCEL_WARNING_4TH',
  SETTING_PRIVACY_INFO_NOTICE = 'SETTING_PRIVACY_INFO_NOTICE',
  SETTING_PRIVACY_CAPTION = 'SETTING_PRIVACY_CAPTION',

  SETTING_USER_DETAILS_TITLE = 'SETTING_USER_DETAILS_TITLE',
  SETTING_USER_DETAILS_FORM_FIRM_NAME_LABEL = 'SETTING_USER_DETAILS_FORM_FIRM_NAME_LABEL',
  SETTING_USER_DETAILS_FORM_EMAIL_LABEL = 'SETTING_USER_DETAILS_FORM_EMAIL_LABEL',
  SETTING_USER_DETAILS_FORM_FIRM_LOCATION_LABEL = 'SETTING_USER_DETAILS_FORM_FIRM_LOCATION_LABEL',
  SETTING_USER_DETAILS_FORM_PMS_LABEL = 'SETTING_USER_DETAILS_FORM_PMS_LABEL',
  SETTING_USER_DETAILS_BANNER = 'SETTING_USER_DETAILS_BANNER',
  SETTING_USER_DETAILS_EDIT_BUTTON_LABEL = 'SETTING_USER_DETAILS_EDIT_BUTTON_LABEL',
  SETTING_USER_DETAILS_CHANGE_PASSWORD_BUTTON_LABEL = 'SETTING_USER_DETAILS_CHANGE_PASSWORD_BUTTON_LABEL',
  SETTING_USER_DETAILS_EDIT_FORM_EMAIL_LABEL = 'SETTING_USER_DETAILS_EDIT_FORM_EMAIL_LABEL',
  SETTING_USER_DETAILS_EDIT_FORM_FIRM_NAME_LABEL = 'SETTING_USER_DETAILS_EDIT_FORM_FIRM_NAME_LABEL',
  SETTING_USER_DETAILS_EDIT_FORM_FIRM_LOCATION_LABEL = 'SETTING_USER_DETAILS_EDIT_FORM_FIRM_LOCATION_LABEL',
  SETTING_USER_DETAILS_EDIT_FORM_PMS_LABEL = 'SETTING_USER_DETAILS_EDIT_FORM_PMS_LABEL',
  SETTING_USER_DETAILS_EDIT_FORM_LOCATION_PROBATE_MATCHING_LABEL = 'SETTING_USER_DETAILS_EDIT_FORM_LOCATION_PROBATE_MATCHING_LABEL',
  SETTING_USER_DETAILS_EDIT_FORM_CANCEL_BUTTON_LABEL = 'SETTING_USER_DETAILS_EDIT_FORM_CANCEL_BUTTON_LABEL',
  SETTING_USER_DETAILS_EDIT_FORM_SAVE_BUTTON_LABEL = 'SETTING_USER_DETAILS_EDIT_FORM_SAVE_BUTTON_LABEL',

  SETTING_MANAGE_USER_TITLE = 'SETTING_MANAGE_USER_TITLE',
  SETTING_MANAGE_USER_EDIT_USER_TITLE = 'SETTING_MANAGE_USER_EDIT_USER_TITLE',
  SETTING_MANAGE_USER_INVITE_USER_TITLE = 'SETTING_MANAGE_USER_INVITE_USER_TITLE',
  SETTING_MANAGE_USER_FORM_SEARCH_PLACEHOLDER = 'SETTING_MANAGE_USER_FORM_SEARCH_PLACEHOLDER',
  SETTING_MANAGE_USER_INVITE_USER_BUTTON = 'SETTING_MANAGE_USER_INVITE_USER_BUTTON',
  SETTING_MANAGE_USER_REMOVE_MODAL_CONTENT = 'SETTING_MANAGE_USER_REMOVE_MODAL_CONTENT',
  SETTING_MANAGE_USER_REMOVE_MODAL_CANCEL_BUTTON_LABEL = 'SETTING_MANAGE_USER_REMOVE_MODAL_CANCEL_BUTTON_LABEL',
  SETTING_MANAGE_USER_REMOVE_MODAL_CONFIRM_BUTTON_LABEL = 'SETTING_MANAGE_USER_REMOVE_MODAL_CONFIRM_BUTTON_LABEL',
  SETTING_MANAGE_USER_TABLE_COL_NAME = 'SETTING_MANAGE_USER_TABLE_COL_NAME',
  SETTING_MANAGE_USER_TABLE_COL_EMAIL = 'SETTING_MANAGE_USER_TABLE_COL_EMAIL',
  SETTING_MANAGE_USER_TABLE_COL_ACTION = 'SETTING_MANAGE_USER_TABLE_COL_ACTION',
  SETTING_MANAGE_USER_TABLE_COL_ACTION_EDIT = 'SETTING_MANAGE_USER_TABLE_COL_ACTION_EDIT',
  SETTING_MANAGE_USER_TABLE_COL_ACTION_DELETE = 'SETTING_MANAGE_USER_TABLE_COL_ACTION_DELETE',
  SETTING_MANAGE_USER_INVITE_USER_FORM_NAME = 'SETTING_MANAGE_USER_INVITE_USER_FORM_NAME',
  SETTING_MANAGE_USER_INVITE_USER_FORM_EMAIL = 'SETTING_MANAGE_USER_INVITE_USER_FORM_EMAIL',
  SETTING_MANAGE_USER_INVITE_USER_FORM_PERMISSION = 'SETTING_MANAGE_USER_INVITE_USER_FORM_PERMISSION',
  SETTING_MANAGE_USER_INVITE_USER_CANCEL_BUTTON_LABEL = 'SETTING_MANAGE_USER_INVITE_USER_CANCEL_BUTTON_LABEL',
  SETTING_MANAGE_USER_INVITE_USER_SAVE_BUTTON_LABEL = 'SETTING_MANAGE_USER_INVITE_USER_SAVE_BUTTON_LABEL',

  LOGIN_LOGIN_HERO_TEXT = 'LOGIN_LOGIN_HERO_TEXT',
  LOGIN_LOGIN_EMAIL_PASSWORD_OPTION = 'LOGIN_LOGIN_EMAIL_PASSWORD_OPTION',
  LOGIN_LOGIN_AUTHENTICATION_CODE_OPTION = 'LOGIN_LOGIN_AUTHENTICATION_CODE_OPTION',
  LOGIN_LOGIN_EMAIL_PASSWORD_FORM_EMAIL_LABEL = 'LOGIN_LOGIN_EMAIL_PASSWORD_FORM_EMAIL_LABEL',
  LOGIN_LOGIN_EMAIL_PASSWORD_FORM_EMAIL_PLACEHOLDER = 'LOGIN_LOGIN_EMAIL_PASSWORD_FORM_EMAIL_PLACEHOLDER',
  LOGIN_LOGIN_EMAIL_PASSWORD_FORM_PASSWORD_LABEL = 'LOGIN_LOGIN_EMAIL_PASSWORD_FORM_PASSWORD_LABEL',
  LOGIN_LOGIN_EMAIL_PASSWORD_FORM_PASSWORD_PLACEHOLDER = 'LOGIN_LOGIN_EMAIL_PASSWORD_FORM_PASSWORD_PLACEHOLDER',
  LOGIN_LOGIN_EMAIL_PASSWORD_FORGOT_PASSWORD_BUTTON_LABEL = 'LOGIN_LOGIN_EMAIL_PASSWORD_FORGOT_PASSWORD_BUTTON_LABEL',
  LOGIN_LOGIN_BUTTON_LABEL = 'LOGIN_LOGIN_BUTTON_LABEL',
  LOGIN_BACK_BUTTON_LABEL = 'LOGIN_BACK_BUTTON_LABEL',
  LOGIN_SEND_CODE_BUTTON_LABEL = 'LOGIN_SEND_CODE_BUTTON_LABEL',
  LOGIN_RESEND_EMAIL_BUTTON_LABEL = 'LOGIN_RESEND_EMAIL_BUTTON_LABEL',
  LOGIN_LOGIN_ACCOUNT_QUESTION = 'LOGIN_LOGIN_ACCOUNT_QUESTION',
  LOGIN_LOGIN_REGISTER_BUTTON_LABEL = 'LOGIN_LOGIN_REGISTER_BUTTON_LABEL',
  LOGIN_LOGIN_AUTHENTICATION_CODE_LABEL = 'LOGIN_LOGIN_AUTHENTICATION_CODE_LABEL',
  LOGIN_LOGIN_AUTHENTICATION_CODE_PLACEHOLDER = 'LOGIN_LOGIN_AUTHENTICATION_CODE_PLACEHOLDER',

  REGISTER_REGISTER_TITLE = 'REGISTER_REGISTER_TITLE',
  REGISTER_REGISTER_ACCOUNT_DETAIL_SUBTITLE = 'REGISTER_REGISTER_ACCOUNT_DETAIL_SUBTITLE',
  REGISTER_REGISTER_FORM_EMAIL_LABEL = 'REGISTER_REGISTER_FORM_EMAIL_LABEL',
  REGISTER_REGISTER_FORM_EMAIL_PLACEHOLDER = 'REGISTER_REGISTER_FORM_EMAIL_PLACEHOLDER',
  REGISTER_REGISTER_FORM_NAME_LABEL = 'REGISTER_REGISTER_FORM_NAME_LABEL',
  REGISTER_REGISTER_FORM_NAME_PLACEHOLDER = 'REGISTER_REGISTER_FORM_NAME_PLACEHOLDER',
  REGISTER_REGISTER_FORM_PHONE_LABEL = 'REGISTER_REGISTER_FORM_PHONE_LABEL',
  REGISTER_REGISTER_FORM_PASSWORD_LABEL = 'REGISTER_REGISTER_FORM_PASSWORD_LABEL',
  REGISTER_REGISTER_FORM_PASSWORD_PLACEHOLDER = 'REGISTER_REGISTER_FORM_PASSWORD_PLACEHOLDER',
  REGISTER_REGISTER_FORM_PASSWORD_TOOLTIP_1ST_ITEM = 'REGISTER_REGISTER_FORM_PASSWORD_TOOLTIP_1ST_ITEM',
  REGISTER_REGISTER_FORM_PASSWORD_TOOLTIP_2ND_ITEM = 'REGISTER_REGISTER_FORM_PASSWORD_TOOLTIP_2ND_ITEM',
  REGISTER_REGISTER_FORM_PASSWORD_TOOLTIP_3RD_ITEM = 'REGISTER_REGISTER_FORM_PASSWORD_TOOLTIP_3RD_ITEM',
  REGISTER_REGISTER_FORM_PASSWORD_TOOLTIP_4TH_ITEM = 'REGISTER_REGISTER_FORM_PASSWORD_TOOLTIP_4TH_ITEM',
  REGISTER_REGISTER_BUSINESS_DETAIL_SUBTITLE = 'REGISTER_REGISTER_BUSINESS_DETAIL_SUBTITLE',
  REGISTER_REGISTER_FORM_FIRM_NAME_LABEL = 'REGISTER_REGISTER_FORM_FIRM_NAME_LABEL',
  REGISTER_REGISTER_FORM_FIRM_NAME_PLACEHOLDER = 'REGISTER_REGISTER_FORM_FIRM_NAME_PLACEHOLDER',
  REGISTER_REGISTER_FORM_PMS_LABEL = 'REGISTER_REGISTER_FORM_PMS_LABEL',
  REGISTER_REGISTER_FORM_PMS_TOOLTIP_MESSAGE = 'REGISTER_REGISTER_FORM_PMS_TOOLTIP_MESSAGE',
  REGISTER_REGISTER_FORM_ABN_LABEL = 'REGISTER_REGISTER_FORM_ABN_LABEL',
  REGISTER_REGISTER_FORM_ABN_PLACEHOLDER = 'REGISTER_REGISTER_FORM_ABN_PLACEHOLDER',
  REGISTER_REGISTER_FORM_STREET_NUMBER_LABEL = 'REGISTER_REGISTER_FORM_STREET_NUMBER_LABEL',
  REGISTER_REGISTER_FORM_STREET_NUMBER_PLACEHOLDER = 'REGISTER_REGISTER_FORM_STREET_NUMBER_PLACEHOLDER',
  REGISTER_REGISTER_FORM_STREET_NAME_LABEL = 'REGISTER_REGISTER_FORM_STREET_NAME_LABEL',
  REGISTER_REGISTER_FORM_STREET_NAME_PLACEHOLDER = 'REGISTER_REGISTER_FORM_STREET_NAME_PLACEHOLDER',
  REGISTER_REGISTER_FORM_COUNTRY_LABEL = 'REGISTER_REGISTER_FORM_COUNTRY_LABEL',
  REGISTER_REGISTER_FORM_STATE_LABEL = 'REGISTER_REGISTER_FORM_STATE_LABEL',
  REGISTER_REGISTER_FORM_POST_CODE_LABEL = 'REGISTER_REGISTER_FORM_POST_CODE_LABEL',
  REGISTER_REGISTER_FORM_SUBURBS_LABEL = 'REGISTER_REGISTER_FORM_SUBURBS_LABEL',
  REGISTER_REGISTER_AGREE_TC_LABEL = 'REGISTER_REGISTER_AGREE_TC_LABEL',
  REGISTER_REGISTER_TC_BUTTON_LABEL = 'REGISTER_REGISTER_TC_BUTTON_LABEL',
  REGISTER_REGISTER_POLICY_BUTTON_LABEL = 'REGISTER_REGISTER_POLICY_BUTTON_LABEL',
  REGISTER_REGISTER_REGISTER_BUTTON_LABEL = 'REGISTER_REGISTER_REGISTER_BUTTON_LABEL',
  REGISTER_REGISTER_PROMPTING_ACCOUNT_LABEL = 'REGISTER_REGISTER_PROMPTING_ACCOUNT_LABEL',
  REGISTER_REGISTER_LOGIN_BUTTON_LABEL = 'REGISTER_REGISTER_LOGIN_BUTTON_LABEL',
  REGISTER_REGISTER_MEDIA_NAME = 'REGISTER_REGISTER_MEDIA_NAME',

  REGISTER_REGISTER_TERM_AND_CONDITION_LINK = 'REGISTER_REGISTER_TERM_AND_CONDITION_LINK',
  REGISTER_REGISTER_PRIVACY_POLICY_LINK = 'REGISTER_REGISTER_PRIVACY_POLICY_LINK',

  SETUP_SETTING_SLIDER_1ST_TITLE = 'SETUP_SETTING_SLIDER_1ST_TITLE',
  SETUP_SETTING_SLIDER_1ST_SUBTITLE = 'SETUP_SETTING_SLIDER_1ST_SUBTITLE',
  SETUP_SETTING_SLIDER_2ND_TITLE = 'SETUP_SETTING_SLIDER_2ND_TITLE',
  SETUP_SETTING_SLIDER_2ND_SUBTITLE = 'SETUP_SETTING_SLIDER_2ND_SUBTITLE',
  SETUP_SETTING_SLIDER_2ND_LABEL_1ST = 'SETUP_SETTING_SLIDER_2ND_LABEL_1ST',
  SETUP_SETTING_SLIDER_2ND_LABEL_2ND = 'SETUP_SETTING_SLIDER_2ND_LABEL_2ND',
  SETUP_SETTING_SLIDER_3RD_TITLE = 'SETUP_SETTING_SLIDER_3RD_TITLE',
  SETUP_SETTING_SLIDER_3RD_SUBTITLE = 'SETUP_SETTING_SLIDER_3RD_SUBTITLE',
  SETUP_SETTING_SLIDER_3RD_LABEL_1ST = 'SETUP_SETTING_SLIDER_3RD_LABEL_1ST',
  SETUP_SETTING_SLIDER_3RD_LABEL_2ND = 'SETUP_SETTING_SLIDER_3RD_LABEL_2ND',
  SETUP_SETTING_SLIDER_3RD_LABEL_3RD = 'SETUP_SETTING_SLIDER_3RD_LABEL_3RD',
  SETUP_SETTING_SLIDER_3RD_LABEL_4TH = 'SETUP_SETTING_SLIDER_3RD_LABEL_4TH',
  SETUP_SETTING_SLIDER_DOWNLOAD_BUTTON_LABEL = 'SETUP_SETTING_SLIDER_DOWNLOAD_BUTTON_LABEL',
  SETUP_SETTING_SLIDER_SUBCRIBED_ILLUSTRATION_NAME = 'SETUP_SETTING_SLIDER_SUBCRIBED_ILLUSTRATION_NAME',
  SETUP_SETTING_SLIDER_NOTIFICATION_ILLUSTRATION_NAME = 'SETUP_SETTING_SLIDER_NOTIFICATION_ILLUSTRATION_NAME',
  SETUP_SETTING_SLIDER_INTEGRATION_DOWNLOAD_ILLUSTRATION_NAME = 'SETUP_SETTING_SLIDER_INTEGRATION_DOWNLOAD_ILLUSTRATION_NAME',
  SETUP_SETTING_SLIDER_INTEGRATION_CHOOSE_ILLUSTRATION_NAME = 'SETUP_SETTING_SLIDER_INTEGRATION_CHOOSE_ILLUSTRATION_NAME',
  SETUP_SETTING_SLIDER_PUBLIC_SEARCH_CHECKBOX_LABEL = 'SETUP_SETTING_SLIDER_PUBLIC_SEARCH_CHECKBOX_LABEL',

  UNDER_REVIEW_TITLE_1 = 'UNDER_REVIEW_TITLE_1',
  UNDER_REVIEW_TITLE_2 = 'UNDER_REVIEW_TITLE_2',
  UNDER_REVIEW_DESC_1 = 'UNDER_REVIEW_DESC_1',
  UNDER_REVIEW_DESC_2 = 'UNDER_REVIEW_DESC_2',
  UNDER_REVIEW_ILLUSTRATION_NAME = 'UNDER_REVIEW_ILLUSTRATION_NAME',

  MISSING_WILL_CONFIRMATION_TITLE = 'MISSING_WILL_CONFIRMATION_TITLE',
  MISSING_WILL_CONFIRMATION_DESC = 'MISSING_WILL_CONFIRMATION_DESC',
  MISSING_WILL_CONFIRMATION_BUTTON_LABEL = 'MISSING_WILL_CONFIRMATION_BUTTON_LABEL',
  MISSING_WILL_CONFIRMATION_HERO_TEXT = 'MISSING_WILL_CONFIRMATION_HERO_TEXT',
  MISSING_WILL_CONFIRMATION_THANK_YOU_TITLE = 'MISSING_WILL_CONFIRMATION_THANK_YOU_TITLE',
  MISSING_WILL_CONFIRMATION_THANK_YOU_DESC = 'MISSING_WILL_CONFIRMATION_THANK_YOU_DESC',
  MISSING_WILL_CONFIRMATION_THANK_YOU_SIGN_UP_INVITATION_LABEL = 'MISSING_WILL_CONFIRMATION_THANK_YOU_SIGN_UP_INVITATION_LABEL',
  MISSING_WILL_CONFIRMATION_THANK_YOU_SIGN_UP_BUTTON_LABEL = 'MISSING_WILL_CONFIRMATION_THANK_YOU_SIGN_UP_BUTTON_LABEL',

  CHARGING_PRICING_EXPLAIN_TEXT = 'CHARGING_PRICING_EXPLAIN_TEXT',

  PASSWORD_FORGOT_PASSWORD_FORM_TITLE = 'PASSWORD_FORGOT_PASSWORD_FORM_TITLE',
  PASSWORD_FORGOT_PASSWORD_FORM_AFTER_SUBMIT_EMAIL_LABEL = 'PASSWORD_FORGOT_PASSWORD_FORM_AFTER_SUBMIT_EMAIL_LABEL',
  PASSWORD_FORGOT_PASSWORD_FORM_EMAIL_LABEL = 'PASSWORD_FORGOT_PASSWORD_FORM_EMAIL_LABEL',
  PASSWORD_FORGOT_PASSWORD_FORM_EMAIL_PLACEHOLDER = 'PASSWORD_FORGOT_PASSWORD_FORM_EMAIL_PLACEHOLDER',
  PASSWORD_FORGOT_PASSWORD_FORM_SEND_EMAIL_BUTTON_LABEL = 'PASSWORD_FORGOT_PASSWORD_FORM_SEND_EMAIL_BUTTON_LABEL',
  PASSWORD_FORGOT_PASSWORD_FORM_BACK_BUTTON_LOGIN_LABEL = 'PASSWORD_FORGOT_PASSWORD_FORM_BACK_BUTTON_LOGIN_LABEL',
  PASSWORD_FORGOT_PASSWORD_FORM_BACK_BUTTON_HOME_LABEL = 'PASSWORD_FORGOT_PASSWORD_FORM_BACK_BUTTON_HOME_LABEL',
  PASSWORD_FORGOT_PASSWORD_ILLUSTRATION_NAME = 'PASSWORD_FORGOT_PASSWORD_ILLUSTRATION_NAME'
}
