import { IUserInfo, INotification, ISyncConfig } from '@models/setting'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { settingInitial } from '@store/initials/settingInitial'
import { SettingTypes } from '@store/types/settingTypes'

const settingSlice = createSlice({
  name: 'setting',
  initialState: settingInitial,
  reducers: {
    setUnlocked: (state: SettingTypes, { payload }: PayloadAction<boolean>) => {
      state.unlocked = payload
    },
    setLocked: (state: SettingTypes, { payload }: PayloadAction<boolean>) => {
      state.locked = payload
    },
    setUserInfoDetail: (state: SettingTypes, { payload }: PayloadAction<IUserInfo>) => {
      return { ...state, infoDetail: { ...state.infoDetail, ...payload } }
    },
    setNotification: (state: SettingTypes, { payload }: PayloadAction<INotification>) => {
      return { ...state, notification: { ...state.notification, ...payload } }
    },
    resetSetting: () => settingInitial,
    setSyncConfig: (state: SettingTypes, { payload }: PayloadAction<ISyncConfig>) => {
      return { ...state, syncConfig: { ...state.syncConfig, ...payload } }
    }
  }
})

export const {
  setUnlocked,
  setLocked,
  setUserInfoDetail,
  setNotification,
  resetSetting,
  setSyncConfig
} = settingSlice.actions

export default settingSlice.reducer
