import { ICommonSearch } from '@models/common';
import { ISilentSearchRequests, IQueryMySilentSearchRequests, IQueryReceivedWillRequestsAsync, IReceivedWillRequests } from '@models/silentWills';
export const silentWillIntial: ICommonSearch<IQueryMySilentSearchRequests, ISilentSearchRequests[]>  = {
  search: {},
  data: [],
  currentPage: 0,
  totalPage: 0
}

export const receivedWillIntial: ICommonSearch<IQueryReceivedWillRequestsAsync, IReceivedWillRequests[]>  = {
  search: {},
  data: [],
  currentPage: 0,
  totalPage: 0
}