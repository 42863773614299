import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ICommonSearch } from '@models/common'
import { historyOrderIntial } from '@store/initials/historyOrderIntial'
import { IHistoryOrder, IQueryHistoryOrders } from '@models/historyOrders'

const historyOrderSlice = createSlice({
  name: 'historyOrder',
  initialState: historyOrderIntial,
  reducers: {
    savingHistoryOrder: (
      state: ICommonSearch<IQueryHistoryOrders, IHistoryOrder[]>,
      action: PayloadAction<ICommonSearch<IQueryHistoryOrders, IHistoryOrder[]>>
    ) => {
      Object.assign(state, action.payload)
    }
  }
})

export const { savingHistoryOrder } = historyOrderSlice.actions

export default historyOrderSlice.reducer
