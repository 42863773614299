import { TChargeableSearch } from '@store/types/chargeableSearchWills'

export const chargeableSearchWillsInitial: TChargeableSearch = {
  search: {
    firstName: '',
    lastName: '',
    birthDateRange: undefined
  },
  data: {
    exactWills: [],
    otherWills: []
  },
  parentOrderId: 0
}
