/** @jsxImportSource theme-ui */
import { RouterProvider } from 'react-router-dom'
import { Suspense, useEffect } from 'react'

import Fallback from '@components/Fallback/Fallback'
import { MarkDownTextProvider } from '@contexts/MarkdownTextProvider'
import { initialiseLogger } from '@utils/datadog'
import router from '@routes/routes'
import './styles/globals.scss'

const App = () => {
  useEffect(() => {
    // start up datadog
    initialiseLogger({
      clientToken: process.env.CLIENT_TOKEN_DD || '',
      env: process.env.NODE_ENV || '',
      service: 'willregistry-ui-web',
      site: 'datadoghq.com'
    })

    const loadingElement = document.getElementById('loading')
    const rootElement = document.getElementById('root')

    if (loadingElement) {
      loadingElement?.classList.add('lds-ripple-display-hide')

      setTimeout(() => {
        loadingElement.remove()
      }, 2000)
    }
    if (rootElement) {
      setTimeout(() => {
        rootElement.classList.add('root-show')
      }, 200)
    }
  }, [])
  return (
    <MarkDownTextProvider>
      <Suspense fallback={<Fallback />}>
        <RouterProvider router={router} fallbackElement={<Fallback />} />
      </Suspense>
    </MarkDownTextProvider>
  )
}
export default App
