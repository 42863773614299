import { useState, useEffect, useCallback } from 'react'
import { getMarkdownText } from '@apis/markdownText'
import { AxiosError } from 'axios'
import { useToastContext, Illustration, IllustrationName } from '@infotrack/zenith-ui'
import { MarkdownKey } from '@constants/markdown'
import { dynamicVariableRegex, httpsRegex } from '@utils/Markdown/util'
import { logger } from '@utils/datadog'
interface IHookReturn {
  loading: boolean
  getText: (key: MarkdownKey, dynamicVariables?: Record<string, any>) => string
  getMedia: (key: MarkdownKey) => string | React.ReactNode
  fetchMarkdownText: () => Promise<string>
}

export const useMarkdownText = (): IHookReturn => {
  const [loading, setLoading] = useState<boolean>(false)
  const toastContext = useToastContext()

  const [markdownText, setMarkdownText] = useState<Record<string, string>>({})

  useEffect(() => {
    const data = getFromLocalStorage()
    if (data) {
      setMarkdownText(JSON.parse(data))
    }
    fetchMarkdownText()
  }, [])

  const saveToLocalStorage = (data: string) => {
    localStorage.setItem('markdownText', data)
  }
  const getFromLocalStorage = () => {
    return localStorage.getItem('markdownText')
  }

  const getText = useCallback(
    (key: MarkdownKey, dynamicVariables?: Record<string, any>): string => {
      const value = markdownText[key]
      if (!value) {
        logger.error(`Markdown Key: Text Key ${key} not found`)
        return ''
      }
      if (dynamicVariables) {
        return value.replace(dynamicVariableRegex, (_, g) => dynamicVariables[g] ?? '')
      }
      return value
    },
    [markdownText]
  )

  const getMedia = useCallback(
    (key: MarkdownKey): string | React.ReactNode => {
      const value = markdownText[key]
      if (!value) {
        logger.error(`Markdown Key: Media Key ${key} not found`)
        return <></>
      }
      if (httpsRegex.test(value)) {
        return <img src={value} alt={key} />
      }
      return <Illustration name={value as IllustrationName} alt={value} />
    },
    [markdownText]
  )

  const fetchMarkdownText = () => {
    const options = {
      onSuccess: (data: string) => {
        setMarkdownText(JSON.parse(data))
        saveToLocalStorage(data)
      },
      onError: (error: AxiosError) => {
        if (error) {
          toastContext.error({
            message: 'Something went wrong',
            title: 'Markdown Text'
          })
        }
      },
      willCallAPI: () => setLoading(true),
      didCallAPI: () => setLoading(false)
    }
    return getMarkdownText(options)
  }
  return { loading, getText, fetchMarkdownText, getMedia }
}
