import { ICommonSearch } from '@models/common'
import { IWills, ISearchWill } from '@models/wills'

export const willInitial: ICommonSearch<ISearchWill, IWills[]> = {
  search: {
    firstName: '',
    lastName: '',
    birthDateRange: undefined
  },
  data: [],
  //TODO remove
  currentPage: 0,
  totalPage: 0,
  parentOrderId: 0
}
