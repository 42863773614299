import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ICommonSearch } from '@models/common'
import { ISilentSearchRequests, IQueryMySilentSearchRequests } from '@models/silentWills'
import { silentWillIntial } from '@store/initials/silentWillIntial'

const silentWillSlice = createSlice({
  name: 'silentWill',
  initialState: silentWillIntial,
  reducers: {
    savingMySilentSearchRequest: (
      state: ICommonSearch<IQueryMySilentSearchRequests, ISilentSearchRequests[]>,
      action: PayloadAction<ICommonSearch<IQueryMySilentSearchRequests, ISilentSearchRequests[]>>
    ) => {
      Object.assign(state, action.payload)
    },
    resetMySilentSearchRequest: () => silentWillIntial
  }
})

export const { savingMySilentSearchRequest, resetMySilentSearchRequest } = silentWillSlice.actions

export default silentWillSlice.reducer
