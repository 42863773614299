import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { commonInitial } from '@store/initials/commonInitial'
import { CommonTypes } from '@store/types/commonTypes'
import { IPermissionUser } from '@models/setting'

const commonSlice = createSlice({
  name: 'common',
  initialState: commonInitial,
  reducers: {
    clickChangePassword: (state: CommonTypes, { payload }: PayloadAction<boolean>) => {
      state.isClickChangePassword = payload
    },
    setDefaultListPermission: (
      state: CommonTypes,
      { payload: { defaultListPermission, availablePermissions } }: PayloadAction<IPermissionUser>
    ) => {
      return {
        ...state,
        defaultListPermission,
        availablePermissions
      }
    },
    resetCommon: () => commonInitial
  }
})

export const { clickChangePassword, setDefaultListPermission, resetCommon } = commonSlice.actions

export default commonSlice.reducer
