import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { totalCountInitial } from '@store/initials/totalCountInitial'
import { totalCountType } from '@store/types/totalCountTypes'

const totalCountSlice = createSlice({
  name: 'totalCount',
  initialState: totalCountInitial,
  reducers: {
    setLoadingTotalCount: (
      state: totalCountType,
      { payload }: PayloadAction<{ loading: boolean }>
    ) => {
      return { ...state, loading: payload.loading }
    },
    setTotalWills: (state: totalCountType, { payload }: PayloadAction<number>) => {
      return { ...state, willsCount: payload }
    },
    setTotalDeathNotices: (state: totalCountType, { payload }: PayloadAction<number>) => {
      return { ...state, deathNoticesCount: payload }
    },
    setProbateNotices: (state: totalCountType, { payload }: PayloadAction<number>) => {
      return { ...state, probateNoticesCount: payload }
    }
  }
})

export const { setTotalWills, setProbateNotices, setTotalDeathNotices, setLoadingTotalCount } =
  totalCountSlice.actions
export default totalCountSlice.reducer
