import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { uiElementInitial } from '@store/initials/uiElementInitial'
import { UiElementTypes } from '@store/types/uiElementTypes'

const uiElementSlice = createSlice({
  name: 'uiElement',
  initialState: uiElementInitial,
  reducers: {
    showToast: (state: UiElementTypes, { payload }: PayloadAction<boolean>) => {
      state.isToast = payload
    },
    resetCommon: () => uiElementInitial
  }
})

export const { showToast } = uiElementSlice.actions

export default uiElementSlice.reducer
