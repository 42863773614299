import { ICommonSearch } from '@models/common';
import { IProbateNotices, ISearchProbateNotice } from '@models/probateNotices';

export const probateNoticeInitial: ICommonSearch<ISearchProbateNotice, IProbateNotices[]>  = {
  search: {
    firstName: '',
    lastName: '',
    dateOfDeath : '',
    states: undefined,
    dateOfBirthRange: undefined
  },
  data: [],
  currentPage: 0,
  totalPage: 0
}